import { Cell, Spinner, unstable_Popover as Popover } from '@vkontakte/vkui';
import { UserStore } from '../../stores/user';
import css from './UserDropdown.css';
import { useCallback, useState } from 'react';
import { Icon20DoorArrowRightOutline, Icon20PodcastOutline, Icon36ChevronDownOutline } from '@vkontakte/icons';
import clsx from 'clsx';
import { AuthStore } from '../../stores/auth';
import { useNavigate } from 'react-router';
import { ScreenPath } from '../../constant/screen';

export const UserDropdown = () => {
	const username = UserStore.useSelector('username');
	const [isOpen, setIsOpen] = useState(false);
	const isLogoutFetching = AuthStore.useSelector('isLogoutFetching');
	const { logout } = AuthStore.useActions();
	const navigate = useNavigate();

	const goToPodcasts = useCallback(() => {
		navigate(ScreenPath.PODCASTS);
	}, [navigate]);

	return (
		<Popover
			shown={isOpen}
			onShownChange={setIsOpen}
			className={css.popover}
			placement="bottom"
			sameWidth
			action="click"
			content={
				<>
					<Cell before={<Icon20PodcastOutline />} onClick={goToPodcasts}>
						Мои подкасты
					</Cell>
					<Cell before={<Icon20DoorArrowRightOutline />} onClick={logout} after={isLogoutFetching && <Spinner />}>
						Выйти
					</Cell>
				</>
			}
		>
			<span className={css.username}>
				{username} <Icon36ChevronDownOutline className={clsx(css.chevronIcon, { [css.chevronIcon__open]: isOpen })} />
			</span>
		</Popover>
	);
};
