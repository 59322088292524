import { ChangeEvent, ReactNode, useCallback } from 'react';
import { FieldLayout } from '../FieldLayout';
import { Select, Spinner } from '@vkontakte/vkui';
import { Option } from '../../../core/domain/Option/Option.type';

export type SelectFieldProps<Value extends string> = {
	name?: string;
	className?: string;
	label?: ReactNode;
	error?: ReactNode;
	value?: Option;
	onChange?: (value: Option<Value> | undefined) => void;
	placeholder?: string;
	after?: ReactNode;
	before?: ReactNode;
	required?: boolean;
	options: Option<Value>[];
	loading?: boolean;
	disabled?: boolean;
};

export const SelectField = <Value extends string>(props: SelectFieldProps<Value>) => {
	const {
		className,
		error,
		label,
		onChange,
		value,
		placeholder,
		before,
		after,
		name,
		required,
		options,
		disabled,
		loading,
	} = props;

	const changeHandler = useCallback(
		(event: ChangeEvent<HTMLSelectElement>) => {
			onChange?.(options.find((item) => item.value === event.target.value));
		},
		[onChange, options],
	);

	return (
		<FieldLayout required={required} className={className} error={error} label={label}>
			<Select
				before={before}
				after={loading ? <Spinner /> : after}
				disabled={disabled || loading}
				options={options}
				placeholder={placeholder}
				value={value?.value}
				onChange={changeHandler}
				name={name}
			/>
		</FieldLayout>
	);
};
