import { useMemo } from 'react';
import { EpisodeStore } from '../../stores/episode';
import { UserStore } from '../../stores/user';

export const useEpisodeViewScreenBase = () => {
	const { remove, restore, publish } = EpisodeStore.useActions();
	const {
		data: episodeData,
		isDeleting: isEpisodeDeleting,
		isRestoring: isEpisodeRestoring,
		isPublishing: isEpisodePublishing,
	} = EpisodeStore.useSelector('entity');
	const userId = UserStore.useSelector('id');

	const isOwner = useMemo(() => userId === episodeData?.createdById, [episodeData?.createdById, userId]);

	return {
		episodeData,
		isEpisodeDeleting,
		isEpisodeRestoring,
		remove,
		restore,
		publish,
		isOwner,
		isEpisodePublishing,
	};
};
