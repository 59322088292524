import { FormConfig, FormErrors, FormValidating } from './Form.type';
import { FormEvent, ReactNode, useCallback, useMemo, useRef, useState } from 'react';

export const useForm = <Form extends {}>(config: FormConfig<Form>) => {
	const { initialState, validators, onSubmit } = config;

	const [values, setValues] = useState<Partial<Form>>(initialState ?? {});
	const [errors, setErrors] = useState<FormErrors<Form>>({});
	const [validating, setValidating] = useState<FormValidating<Form>>({});
	const nonValidatedFieldsRef = useRef(Object.keys(validators ?? {}));
	const [isSubmitting, setIsSubmitting] = useState(false);

	const validate = useCallback(
		async <Key extends keyof Form>(key: Key, value: Form[Key] | undefined): Promise<boolean> => {
			const currentValidators = validators?.[key];
			if (currentValidators) {
				setValidating((prevState) => ({
					...prevState,
					[key]: true,
				}));

				const prevValue = values[key];
				const errors = (
					await Promise.all(
						currentValidators.map(
							async (validator) =>
								await validator.validate({
									value,
									prevValue,
									state: values,
								}),
						),
					)
				).filter((item) => !!item);

				setErrors((prevState) => ({
					...prevState,
					[key]: errors.length ? errors : undefined,
				}));

				setValidating((prevState) => ({
					...prevState,
					[key]: false,
				}));
				nonValidatedFieldsRef.current = nonValidatedFieldsRef.current.filter((field) => field !== key);

				return !errors.length;
			}

			return true;
		},
		[validators, values],
	);

	const getChangeHandler = useCallback(
		<Key extends keyof Form>(key: Key) =>
			(value: Form[Key] | undefined) => {
				setValues((prevState) => ({
					...prevState,
					[key]: value,
				}));
				validate(key, value);
			},
		[validate],
	);

	const isValid = useMemo(() => {
		const errorValues = Object.values(errors) as (undefined | ReactNode[])[];
		const isInvalid = errorValues.some((item) => {
			return !!item && item.some((item) => !!item);
		});

		const isValidating = Object.values(validating).some((item) => !!item);

		return !isInvalid && !isValidating;
	}, [errors, validating]);

	const checkIsFieldsValid = useCallback(
		async (fields: (keyof Form)[]) => {
			let isAllValid = true;
			for (const key of fields) {
				const isValid = await validate(key, values[key]);
				isAllValid = isAllValid && isValid;
			}

			return isAllValid;
		},
		[validate, values],
	);

	const submitHandler = useCallback(
		async (event: FormEvent) => {
			event.preventDefault();
			setIsSubmitting(true);

			const isValidating = Object.values(validating).some((item) => !!item);

			if (isValidating) {
				setIsSubmitting(false);

				return;
			}

			if (nonValidatedFieldsRef.current.length) {
				const isValid = await checkIsFieldsValid(nonValidatedFieldsRef.current);

				if (!isValid) {
					setIsSubmitting(false);

					return;
				}
			}

			await onSubmit?.(values as Form);
			setIsSubmitting(false);
		},
		[checkIsFieldsValid, onSubmit, validating, values],
	);

	return {
		getChangeHandler,
		values,
		errors,
		validating,
		isValid,
		submitHandler,
		isSubmitting,
	};
};
