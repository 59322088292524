import { PropsWithChildren, ReactNode } from 'react';
import { FormItem } from '@vkontakte/vkui';
import css from './FieldLayout.css';
import clsx from 'clsx';

export type FieldLayoutProps = PropsWithChildren<{
	className?: string;
	label?: ReactNode;
	error?: ReactNode;
	required?: boolean;
}>;

export const FieldLayout = (props: FieldLayoutProps) => {
	const { className, label, error, children, required } = props;

	return (
		<FormItem
			className={clsx(css.root, className)}
			bottom={error}
			top={
				label && (
					<span>
						{label} {required && <span className={css.required}>✱</span>}
					</span>
				)
			}
			status={error ? 'error' : 'default'}
		>
			{children}
		</FormItem>
	);
};
