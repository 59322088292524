import { Header } from '../Header';
import { AppRouteMap } from '../AppRouter/AppRouteMap';
import css from './App.css';
import { Footer } from '../Footer';
import { useLayoutEffect } from 'react';
import { AppStore } from '../../stores/app';

export const App = () => {
	const { initialize } = AppStore.useActions();

	useLayoutEffect(() => {
		initialize();
	}, [initialize]);

	return (
		<div className={css.root}>
			<Header />
			<div className={css.content}>
				<AppRouteMap />
			</div>
			<Footer />
		</div>
	);
};
