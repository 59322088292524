import { BrowserRouter, useLocation } from 'react-router-dom';
import { PropsWithChildren, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router';

export let navigate: ReturnType<typeof useNavigate> = () => {};
export let location: ReturnType<typeof useLocation> = { hash: '', key: '', pathname: '', search: '', state: undefined };
const ExternalNavigator = () => {
	const localNavigate = useNavigate();
	const localLocation = useLocation();

	useLayoutEffect(() => {
		navigate = localNavigate;
	}, [localNavigate]);

	useLayoutEffect(() => {
		location = localLocation;
	}, [localLocation]);

	return <></>;
};

export const AppRouter = (props: PropsWithChildren) => {
	const { children } = props;

	return (
		<BrowserRouter>
			<ExternalNavigator />
			{children}
		</BrowserRouter>
	);
};
