import { ChangeEvent, ReactNode, useCallback } from 'react';
import { FieldLayout } from '../FieldLayout';
import { Checkbox } from '@vkontakte/vkui';

export type CheckboxFieldProps = {
	name?: string;
	className?: string;
	label?: ReactNode;
	error?: ReactNode;
	value?: boolean;
	onChange?: (value: boolean) => void;
	description?: ReactNode;
	titleAfter?: ReactNode;
};

export const CheckboxField = (props: CheckboxFieldProps) => {
	const { className, error, label, onChange, value = false, name, description, titleAfter } = props;

	const changeHandler = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			onChange?.(event.target.checked);
		},
		[onChange],
	);

	return (
		<FieldLayout className={className} error={error}>
			<Checkbox titleAfter={titleAfter} description={description} checked={value} onChange={changeHandler} name={name}>
				{label}
			</Checkbox>
		</FieldLayout>
	);
};
