import { createRoot } from 'react-dom/client';
import { Providers } from './components/Providers';
import { AppWrapper } from './components/AppWrapper';
import { App } from './components/App';
import '@vkontakte/vkui/dist/vkui.css';
import './styles/index.css';

const rootNode = document.getElementById('root');

if (rootNode) {
	const root = createRoot(rootNode);
	root.render(
		<Providers>
			<AppWrapper>
				<App />
			</AppWrapper>
		</Providers>,
	);
}
