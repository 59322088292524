import { Route } from 'react-router-dom';
import { useId, useLayoutEffect } from 'react';
import { useExtendedRoutes } from './ExtendedRoutes';
import { IndexRouteProps, LayoutRouteProps, PathRouteProps } from 'react-router/dist/lib/components';
import { RouteLoaderFunction, RouteLoaderProvider } from './RouteLoader';

export type PublicRouteProps = (
	| Omit<PathRouteProps, 'loader'>
	| Omit<LayoutRouteProps, 'loader'>
	| Omit<IndexRouteProps, 'loader'>
) & {
	loader?: RouteLoaderFunction;
};
export const PublicRoute = (props: PublicRouteProps) => {
	const { loader, element, ...routeProps } = props;
	const id = useId();
	const { push } = useExtendedRoutes();

	useLayoutEffect(() => {
		push(id, <Route {...routeProps} element={<RouteLoaderProvider loader={loader}>{element}</RouteLoaderProvider>} />);
	}, [element, id, loader, push, routeProps]);

	return null;
};
