import { ChangeEvent, FocusEvent, ReactNode, useCallback } from 'react';
import { FieldLayout } from '../FieldLayout';
import { Textarea } from '@vkontakte/vkui';

export type TextareaFieldProps = {
	name?: string;
	className?: string;
	label?: ReactNode;
	error?: ReactNode;
	value?: string;
	onChange?: (value: string) => void;
	changeMode?: 'blur' | 'change';
	placeholder?: string;
	grow?: boolean;
	autoComplete?: string;
	required?: boolean;
};

export const TextareaField = (props: TextareaFieldProps) => {
	const {
		name,
		className,
		error,
		label,
		onChange,
		changeMode = 'change',
		value,
		placeholder,
		autoComplete,
		grow,
		required,
	} = props;

	const changeHandler = useCallback(
		(event: ChangeEvent<HTMLTextAreaElement>) => {
			if (changeMode === 'change') {
				onChange?.(event.target.value);
			}
		},
		[changeMode, onChange],
	);

	const blurHandler = useCallback(
		(event: FocusEvent<HTMLTextAreaElement>) => {
			if (changeMode === 'blur') {
				onChange?.(event.target.value);
			}
		},
		[changeMode, onChange],
	);

	return (
		<FieldLayout required={required} className={className} error={error} label={label}>
			<Textarea
				placeholder={placeholder}
				onChange={changeHandler}
				onBlur={blurHandler}
				name={name}
				autoComplete={autoComplete}
				grow={grow}
				value={value}
			/>
		</FieldLayout>
	);
};
