import { IAppError } from './AppError.interface';
import { CommonErrorCode } from './AppError.constant';
import { ServerErrorUtil } from '../ServerError/ServerError.util';
import { AppErrorUtil } from './AppError.util';
import { AppErrorCode } from './AppError.type';

export class AppError extends Error implements IAppError {
	code: AppErrorCode = CommonErrorCode.APPLICATION;

	constructor(error: unknown) {
		super(error instanceof Error ? error.message : undefined);

		if (ServerErrorUtil.is(error) || AppErrorUtil.is(error)) {
			this.code = error.code;
		}
	}
}
