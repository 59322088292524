import { useInfinityScroll } from '../../hooks/useInfinityScroll';
import { PropsWithChildren, ReactNode } from 'react';
import { Spinner } from '@vkontakte/vkui';
import clsx from 'clsx';
import css from './InfinityScrollContainer.css';

export type InfinityScrollContainerProps = PropsWithChildren<{
	onMarkerIntersect?: () => void;
	className?: string;
	markerNode?: ReactNode;
	hideMarker?: boolean;
}>;

export const InfinityScrollContainer = (props: InfinityScrollContainerProps) => {
	const { onMarkerIntersect = () => {}, className, markerNode, children, hideMarker } = props;
	const { scrollContainerRef, markerRef } = useInfinityScroll({ onMarkerIntersect });

	return (
		<div className={clsx(css.root, className)} ref={scrollContainerRef}>
			{children}
			{!hideMarker && (
				<div className={css.marker} ref={markerRef}>
					{markerNode ?? <Spinner size="large" />}
				</div>
			)}
		</div>
	);
};
