import { ChangeEvent, FocusEvent, HTMLInputTypeAttribute, ReactNode, useCallback } from 'react';
import { FieldLayout } from '../FieldLayout';
import { Input } from '@vkontakte/vkui';

export type InputFieldProps = {
	name?: string;
	className?: string;
	label?: ReactNode;
	error?: ReactNode;
	value?: string;
	onChange?: (value: string) => void;
	changeMode?: 'blur' | 'change';
	placeholder?: string;
	type?: HTMLInputTypeAttribute;
	after?: ReactNode;
	before?: ReactNode;
	autoComplete?: string;
	required?: boolean;
};

export const InputField = (props: InputFieldProps) => {
	const {
		className,
		error,
		label,
		onChange,
		value = '',
		placeholder,
		changeMode = 'change',
		type,
		before,
		after,
		name,
		autoComplete,
		required,
	} = props;

	const changeHandler = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			if (changeMode === 'change') {
				onChange?.(event.target.value);
			}
		},
		[changeMode, onChange],
	);

	const blurHandler = useCallback(
		(event: FocusEvent<HTMLInputElement>) => {
			onChange?.(event.target.value);
		},
		[onChange],
	);

	return (
		<FieldLayout required={required} className={className} error={error} label={label}>
			<Input
				before={before}
				after={after}
				type={type}
				placeholder={placeholder}
				value={value}
				onChange={changeHandler}
				onBlur={blurHandler}
				name={name}
				autoComplete={autoComplete}
			/>
		</FieldLayout>
	);
};
