import { Button, ButtonProps } from '@vkontakte/vkui';
import { Link, RelativeRoutingType, To } from 'react-router-dom';

export type LinkButtonProps = Omit<ButtonProps, 'Component'> & {
	reloadDocument?: boolean;
	replace?: boolean;
	state?: any;
	preventScrollReset?: boolean;
	relative?: RelativeRoutingType;
	to: To;
};

export const LinkButton = (props: LinkButtonProps) => {
	return <Button Component={Link} {...props} />;
};
