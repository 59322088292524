import axios from 'axios';

export async function getFileByUrl(url: string) {
	try {
		const response = await axios.get<Blob>(url, { responseType: 'blob' });
		const fileNameSplit = url.split('/');
		const fileName = fileNameSplit[fileNameSplit.length - 1];

		return new File([response.data], fileName ?? 'image.jpg', {
			lastModified: Date.now(),
			type: response.data.type,
		});
	} catch {
		return null;
	}
}
