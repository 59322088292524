import { HttpStatusCode } from 'axios';
import { IServerError } from './ServerError.interface';
import { ServerError } from './ServerError';

export const ServerErrorUtil = {
	is(data: unknown): data is IServerError {
		return (
			data instanceof ServerError ||
			(typeof data === 'object' &&
				!!data &&
				'code' in data &&
				typeof data.code === 'string' &&
				'status' in data &&
				typeof data.status === 'number' &&
				Object.values(HttpStatusCode).includes(data.status))
		);
	},
};
