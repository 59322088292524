import { Icon56ErrorTriangleOutline } from '@vkontakte/icons';
import { Layout } from '../../components/Layout';
import { Placeholder } from '@vkontakte/vkui';
import { LinkButton } from '../../components/LinkButton';
import { ScreenPath } from '../../constant/screen';
import css from './NotFoundScreen.css';

export const NotFoundScreen = () => {
	return (
		<Layout className={css.root} mode="content" title="Страница не найдена">
			<Placeholder
				icon={<Icon56ErrorTriangleOutline width={100} height={100} className={css.icon} />}
				header={404}
				action={
					<LinkButton size="l" stretched to={ScreenPath.MAIN}>
						Вернуться на главную
					</LinkButton>
				}
			>
				Страница не найдена
			</Placeholder>
		</Layout>
	);
};
