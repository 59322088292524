import { Div, Image, RichCell, Spacing, Title } from '@vkontakte/vkui';
import css from './EpisodeInfo.css';
import { Icon2418CircleOutline } from '@vkontakte/icons';
import clsx from 'clsx';
import { useMemo } from 'react';
import { ReleaseViewModel } from '../../core/domain/Release/Release.type';
import { AudioPlayer } from '../AudioPlayer';

export type EpisodeInfoProps = {
	data: ReleaseViewModel;
	className?: string;
};

export const EpisodeInfo = (props: EpisodeInfoProps) => {
	const { data, className } = props;

	const after = useMemo(() => {
		return data.removed ? 'Удален' : undefined;
	}, [data.removed]);
	const subhead = useMemo(() => {
		if (!data.publishedAt) {
			return 'Не опубликован';
		}

		const publicationDate = new Date(data.publishedAt);

		return publicationDate.toLocaleDateString('ru', {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		});
	}, [data.publishedAt]);

	return (
		<div className={clsx(css.root, className)}>
			<Div className={css.mainInfo}>
				<div className={css.logoContainer}>
					<Image size={100} src={data.imageUrl ?? ''} />
					<Spacing size={20} />
				</div>
				<RichCell
					className={css.cell}
					subhead={subhead}
					text={<AudioPlayer src={data.audioUrl} />}
					disabled
					after={after}
					afterCaption={data.explicit && <Icon2418CircleOutline />}
				>
					<Title className={css.title} level="2">
						{data.title}
					</Title>
				</RichCell>
			</Div>
			<Spacing size={12} />
			<Div className={css.description}>{data.description}</Div>
		</div>
	);
};
