import { createContext, PropsWithChildren, useCallback, useContext, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Params } from 'react-router-dom';
import { NotFoundScreen } from '../../screens/NotFoundScreen';
import { DataLoadingScreen } from '../../screens/DataLoadingScreen';

export type RouteLoaderContextValue = any;
export const RouteLoaderContext = createContext<RouteLoaderContextValue>(null);

export type RouteLoaderFunction = <RouteParams extends Readonly<Params<string>> = Readonly<Params<string>>>(args: {
	params: RouteParams;
}) => Promise<any> | any;

export type RouteLoaderProviderProps = PropsWithChildren<{
	loader?: RouteLoaderFunction;
}>;

export const RouteLoaderProvider = (props: RouteLoaderProviderProps) => {
	const { loader, children } = props;
	const [isLoading, setIsLoading] = useState(!!loader);
	const [isErrored, setIsErrored] = useState(false);
	const [data, setData] = useState<any>();
	const params = useParams();

	const loadRouteData = useCallback(async () => {
		if (loader) {
			try {
				setIsLoading(true);
				const data = await loader({ params });
				setData(data);
			} catch {
				setIsErrored(true);
			} finally {
				setIsLoading(false);
			}
		}
	}, [loader, params]);

	useLayoutEffect(() => {
		loadRouteData();
	}, [loadRouteData]);

	if (isErrored) {
		return <NotFoundScreen />;
	}

	if (isLoading) {
		return <DataLoadingScreen />;
	}

	return <RouteLoaderContext.Provider value={data}>{children}</RouteLoaderContext.Provider>;
};

export const useRouterLoaderData = <T extends any>(): T => {
	return useContext(RouteLoaderContext);
};
