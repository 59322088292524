import { AuthStoreState } from './auth.type';
import createHookStore from 'starux/react';
import { AuthApi } from '../../api/auth.api';
import { ScreenPath } from '../../constant/screen';
import { navigate } from '../../components/AppRouter';
import { RegistrationRequestModel } from '../../core/domain/Auth/Registration.type';
import { LoginRequestModel } from '../../core/domain/Auth/Login.type';
import { generateUrl } from '../../util/generateUrl';
import { UserStore } from '../user';
import { AppError } from '../../core/domain/AppError/AppError';

const initialState: AuthStoreState = {
	login: {
		isFetching: false,
		errorCode: null,
	},
	register: {
		isFetching: false,
		errorCode: null,
	},
	logout: {
		isFetching: false,
		errorCode: null,
	},
};

export const AuthStore = createHookStore({
	initialState,
	reducers: {
		register: async (state, values: RegistrationRequestModel) => {
			try {
				state.register.isFetching = true;
				const response = await AuthApi.register(values);
				navigate(generateUrl(ScreenPath.CONFIRM_EMAIL, {}, { email: values.email, userId: response.id }));
			} catch (error) {
				state.register.errorCode = new AppError(error).code;
			} finally {
				state.register.isFetching = false;
			}
		},
		login: async (state, values: LoginRequestModel) => {
			try {
				state.login.isFetching = true;
				state.login.errorCode = null;
				await AuthApi.login(values);
				await UserStore.store.actions.fetchCurrentUser();
			} catch (error: any) {
				state.login.errorCode = new AppError(error).code;
			} finally {
				state.login.isFetching = false;
			}
		},
		logout: async (state) => {
			try {
				state.logout.isFetching = true;
				await AuthApi.logout();
				UserStore.store.actions.clearUserData();
			} catch {
			} finally {
				state.logout.isFetching = false;
			}
		},
	},
	selectors: {
		loginErrorCode: (state) => state.login.errorCode,
		registerErrorCode: (state) => state.register.errorCode,
		isLoginFetching: (state) => state.login.isFetching,
		isLogoutFetching: (state) => state.logout.isFetching,
		isRegisterFetching: (state) => state.register.isFetching,
	},
});
