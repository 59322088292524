import { PropsWithChildren, ReactNode, useEffect } from 'react';
import { Card, Div, Separator, Spacing, Title } from '@vkontakte/vkui';
import css from './Layout.css';
import clsx from 'clsx';

export type LayoutProps = PropsWithChildren<{
	header?: ReactNode;
	title?: string;
	className?: string;
	mode?: 'content' | 'page' | 'fullscreen';
	action?: ReactNode;
}>;

export const Layout = (props: LayoutProps) => {
	const { children, title, className, mode = 'page', header, action } = props;

	useEffect(() => {
		if (title) {
			document.title = `Paprika Подкаст - ${title}`;
		} else {
			document.title = 'Paprika Подкаст';
		}
	}, [title]);

	return (
		<Card
			className={clsx(
				css.root,
				{ [css.root_content]: mode === 'content', [css.root_fullscreen]: mode === 'fullscreen' },
				className,
			)}
			mode="shadow"
		>
			{header && (
				<>
					<header className={css.header}>
						<Title level="1">{header}</Title>
						{action && <div className={css.action}>{action}</div>}
					</header>
					<Spacing>
						<Separator />
					</Spacing>
				</>
			)}

			<Div className={css.container}>{children}</Div>
		</Card>
	);
};
