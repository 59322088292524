import { ApiService } from './api.service';
import {
	ReleaseCreateRequestModel,
	ReleaseCreateResponseModel,
	ReleaseDeleteRequestModel,
	ReleaseDeleteResponseModel,
	ReleaseGetAllRequestModel,
	ReleaseGetAllResponseModel,
	ReleaseGetRequestModel,
	ReleasePublishRequestModel,
	ReleasePublishResponseModel,
	ReleaseResponseModel,
	ReleaseRestoreRequestModel,
	ReleaseRestoreResponseModel,
	ReleaseUpdateRequestModel,
	ReleaseUpdateResponseModel,
} from '../core/domain/Release/Release.type';
import { toFormData } from 'axios';

export const ReleaseApi = {
	getAll: (data: ReleaseGetAllRequestModel) => {
		return ApiService.get<ReleaseGetAllResponseModel>(`/release/all/${data.id}`, {
			params: { page: data.page, size: data.size },
		});
	},
	create: (data: ReleaseCreateRequestModel) => {
		return ApiService.post<ReleaseCreateResponseModel>(`/release/create`, toFormData(data));
	},
	update: (data: ReleaseUpdateRequestModel) => {
		const { id, ...params } = data;

		return ApiService.post<ReleaseUpdateResponseModel>(`/release/${id}/update`, toFormData(params));
	},
	get: (data: ReleaseGetRequestModel) => {
		return ApiService.get<ReleaseResponseModel>(`/release/${data.id}`);
	},
	delete: (data: ReleaseDeleteRequestModel) => {
		return ApiService.post<ReleaseDeleteResponseModel>(`/release/${data.id}/delete`);
	},
	restore: (data: ReleaseRestoreRequestModel) => {
		return ApiService.post<ReleaseRestoreResponseModel>(`/release/${data.id}/restore`);
	},
	publish: (data: ReleasePublishRequestModel) => {
		return ApiService.post<ReleasePublishResponseModel>(`/release/${data.id}/publish`);
	},
};
