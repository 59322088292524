import { Layout } from '../../components/Layout';
import { useEpisodeViewScreenBase } from './useEpisodeViewScreenBase';
import { Button, ButtonGroup, unstable_TextTooltip as TextTooltip } from '@vkontakte/vkui';
import {
	Icon20DeleteOutline,
	Icon20RadiowavesLeftAndRightCircleFillRed,
	Icon20Sync,
	Icon20WriteOutline,
} from '@vkontakte/icons';
import { LinkButton } from '../../components/LinkButton';
import { generatePath } from 'react-router';
import { ScreenPath } from '../../constant/screen';
import { EpisodeInfo } from '../../components/EpisodeInfo';

export const EpisodeViewScreen = () => {
	const { remove, restore, isOwner, isEpisodeRestoring, isEpisodeDeleting, episodeData, isEpisodePublishing, publish } =
		useEpisodeViewScreenBase();

	return (
		<Layout
			title={episodeData ? episodeData.title : 'Эпизод'}
			header="Эпизод"
			action={
				isOwner &&
				episodeData && (
					<ButtonGroup>
						{!episodeData.publishedAt && (
							<TextTooltip text="Опубликовать">
								<Button loading={isEpisodePublishing} mode="link" onClick={publish}>
									<Icon20RadiowavesLeftAndRightCircleFillRed />
								</Button>
							</TextTooltip>
						)}
						<TextTooltip text="Редактировать">
							<LinkButton
								to={generatePath(ScreenPath.EPISODE_EDIT, {
									podcastId: episodeData.channelId,
									episodeId: episodeData.id,
								})}
								mode="link"
							>
								<Icon20WriteOutline />
							</LinkButton>
						</TextTooltip>
						{episodeData.removed ? (
							<TextTooltip text="Восстановить">
								<Button loading={isEpisodeRestoring} mode="link" appearance="positive" onClick={restore}>
									<Icon20Sync />
								</Button>
							</TextTooltip>
						) : (
							<TextTooltip text="Удалить">
								<Button loading={isEpisodeDeleting} mode="link" appearance="negative" onClick={remove}>
									<Icon20DeleteOutline />
								</Button>
							</TextTooltip>
						)}
					</ButtonGroup>
				)
			}
		>
			{episodeData && <EpisodeInfo data={episodeData} />}
		</Layout>
	);
};
