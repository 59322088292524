import {
	EmailConfirmRequestModel,
	EmailResendConfirmByIdRequestModel,
} from '../core/domain/EmailConfirm/EmailConfirm.type';
import { ApiService } from './api.service';

export const EmailConfirmApi = {
	confirm: (data: EmailConfirmRequestModel) => {
		return ApiService.post('/email-confirmation/confirm', data);
	},
	resend: () => {
		return ApiService.post('/email-confirmation/resend-confirm-link');
	},
	resendById: (data: EmailResendConfirmByIdRequestModel) => {
		return ApiService.post('/email-confirmation/resend-confirm-link-by-id', data);
	},
};
