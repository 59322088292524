import { LoginScreen } from '../../screens/LoginScreen';
import { PodcastListScreen } from '../../screens/PodcastListScreen';
import { PodcastViewScreen } from '../../screens/PodcastViewScreen';
import { ScreenPath } from '../../constant/screen';
import { NotFoundScreen } from '../../screens/NotFoundScreen';
import { MainScreen } from '../../screens/MainScreen';
import { ConfirmEmailScreen } from '../../screens/ConfirmEmailScreen';
import { RegistrationScreen } from '../../screens/RegistrationScreen';
import { EmailConfirmedScreen } from '../../screens/EmailConfirmedScreen';
import { ExtendedRoutes } from './ExtendedRoutes';
import { ProtectedRoute } from './ProtectedRoute';
import { UserStatus } from '../../core/domain/User/User.constant';
import { PublicRoute } from './PublicRoute';
import { PodcastFormScreen } from '../../screens/PodcastFormScreen';
import { PodcastStore } from '../../stores/podcast';
import { EpisodeFormScreen } from '../../screens/EpisodeFormScreen';
import { EpisodeStore } from '../../stores/episode';
import { EpisodeViewScreen } from '../../screens/EpisodeViewScreen';

export const AppRouteMap = () => {
	return (
		<ExtendedRoutes>
			<ProtectedRoute statuses={[UserStatus.VERIFIED]} path={ScreenPath.MAIN} element={<MainScreen />} />
			<ProtectedRoute statuses={[UserStatus.UNAUTHORIZED]} path={ScreenPath.LOGIN} element={<LoginScreen />} />
			<ProtectedRoute statuses={[UserStatus.VERIFIED]} path={ScreenPath.PODCASTS} element={<PodcastListScreen />} />
			<ProtectedRoute
				statuses={[UserStatus.VERIFIED]}
				path={ScreenPath.EPISODE_CREATE}
				element={<EpisodeFormScreen key={ScreenPath.EPISODE_CREATE} />}
			/>
			<ProtectedRoute
				statuses={[UserStatus.VERIFIED]}
				path={ScreenPath.EPISODE_EDIT}
				element={<EpisodeFormScreen key={ScreenPath.EPISODE_EDIT} />}
				loader={async (args) => {
					const id = args.params.episodeId;
					if (id) {
						await EpisodeStore.store.actions.get(id);
					}

					return null;
				}}
			/>
			<ProtectedRoute
				statuses={[UserStatus.VERIFIED]}
				path={ScreenPath.EPISODE_VIEW}
				element={<EpisodeViewScreen key={ScreenPath.EPISODE_VIEW} />}
				loader={async (args) => {
					const id = args.params.episodeId;
					if (id) {
						await EpisodeStore.store.actions.get(id);
					}

					return null;
				}}
			/>
			<ProtectedRoute
				statuses={[UserStatus.VERIFIED]}
				path={ScreenPath.PODCAST_CREATE}
				element={<PodcastFormScreen key={ScreenPath.PODCAST_CREATE} />}
			/>
			<ProtectedRoute
				statuses={[UserStatus.VERIFIED]}
				path={ScreenPath.PODCAST_EDIT}
				element={<PodcastFormScreen key={ScreenPath.PODCAST_EDIT} />}
				loader={async (args) => {
					const id = args.params.podcastId;
					if (id) {
						await PodcastStore.store.actions.get(id);
					}

					return null;
				}}
			/>
			<ProtectedRoute
				statuses={[UserStatus.VERIFIED]}
				path={ScreenPath.PODCAST_VIEW}
				element={<PodcastViewScreen key={ScreenPath.PODCAST_VIEW} />}
				loader={async (args) => {
					const id = args.params.podcastId;
					if (id) {
						await PodcastStore.store.actions.get(id);
					}

					return null;
				}}
			/>
			<ProtectedRoute
				statuses={[UserStatus.UNAUTHORIZED, UserStatus.UNVERIFIED]}
				path={ScreenPath.CONFIRM_EMAIL}
				element={<ConfirmEmailScreen />}
			/>
			<PublicRoute path={ScreenPath.CONFIRM} element={<EmailConfirmedScreen />} />
			<ProtectedRoute
				statuses={[UserStatus.UNAUTHORIZED]}
				path={ScreenPath.REGISTER}
				element={<RegistrationScreen />}
			/>
			<PublicRoute path="*" element={<NotFoundScreen />} />
		</ExtendedRoutes>
	);
};
