import createHookStore from 'starux/react';
import { AppError } from '../../core/domain/AppError/AppError';
import { createQueue } from 'starux/middlewares';
import { EpisodeStoreState } from './episode.type';
import { ReleaseApi } from '../../api/release.api';
import { generatePath } from 'react-router';
import { ScreenPath } from '../../constant/screen';
import { ReleaseCreateViewModel, ReleaseUpdateViewModel } from '../../core/domain/Release/Release.type';
import { navigate } from '../../components/AppRouter';
import { ReleaseConverter } from '../../core/domain/Release/Release.converter';

const initialState: EpisodeStoreState = {
	all: {
		data: [],
		page: -1,
		size: 30,
		isFetching: false,
		errorCode: null,
		total: 0,
		isAllFetched: false,
	},
	create: {
		isFetching: false,
		errorCode: null,
	},
	entity: {
		isFetching: false,
		isDeleting: false,
		isRestoring: false,
		isPublishing: false,
		errorCode: null,
		data: null,
	},
	update: {
		isFetching: false,
		errorCode: null,
	},
};
export const EpisodeStore = createHookStore({
	initialState,
	reducers: {
		fetchAll: createQueue(async (state: EpisodeStoreState, podcastId: string, page?: number) => {
			const newPage = page ?? state.all.page + 1;

			if ((!state.all.isAllFetched || page !== undefined) && !state.all.isFetching) {
				try {
					state.all.isFetching = true;
					state.all.errorCode = null;

					const response = await ReleaseApi.getAll({
						page: newPage,
						size: state.all.size,
						id: podcastId,
					});

					state.all.page = newPage;
					state.all.total = response.total;
					const newData = await Promise.all(response.data.map(ReleaseConverter.Entity.fromResponseToView));
					state.all.data = newPage === 0 ? newData : [...state.all.data, ...newData];
					state.all.isAllFetched = response.count === 0;
				} catch (error) {
					state.all.errorCode = new AppError(error).code;
				} finally {
					state.all.isFetching = false;
				}
			}
		}),
		create: async (state, values: ReleaseCreateViewModel) => {
			try {
				state.create.isFetching = true;
				state.create.errorCode = null;
				const response = await ReleaseApi.create(ReleaseConverter.Create.fromViewToRequest(values));
				navigate(
					generatePath(ScreenPath.EPISODE_VIEW, {
						episodeId: response.id,
						podcastId: values.channelId,
					}),
				);
			} catch (error) {
				state.create.errorCode = new AppError(error).code;
			} finally {
				state.create.isFetching = false;
			}
		},
		update: async (state, values: ReleaseUpdateViewModel) => {
			try {
				state.update.isFetching = true;
				state.update.errorCode = null;
				const response = await ReleaseApi.update(ReleaseConverter.Update.fromViewToRequest(values));
				navigate(
					generatePath(ScreenPath.EPISODE_VIEW, {
						episodeId: response.id,
						podcastId: values.channelId,
					}),
				);
			} catch (error) {
				state.update.errorCode = new AppError(error).code;
			} finally {
				state.update.isFetching = false;
			}
		},
		get: async (state, id: string) => {
			try {
				state.entity.isFetching = true;
				state.entity.errorCode = null;
				state.entity.data = await ReleaseConverter.Entity.fromResponseToViewWithAudio(await ReleaseApi.get({ id }));
			} catch (error) {
				state.entity.errorCode = new AppError(error).code;
				throw error;
			} finally {
				state.entity.isFetching = false;
			}
		},
		remove: async (state) => {
			try {
				state.entity.isDeleting = true;
				const id = state.entity.data?.id;

				if (id) {
					const response = await ReleaseApi.delete({ id });
					if (response.id === state.entity.data?.id) {
						state.entity.data.removed = true;
					}
				}
			} catch {
			} finally {
				state.entity.isDeleting = false;
			}
		},
		restore: async (state) => {
			try {
				state.entity.isRestoring = true;
				const id = state.entity.data?.id;

				if (id) {
					const response = await ReleaseApi.restore({ id });
					if (response.id === state.entity.data?.id) {
						state.entity.data.removed = false;
					}
				}
			} catch {
			} finally {
				state.entity.isRestoring = false;
			}
		},
		publish: async (state) => {
			try {
				state.entity.isPublishing = true;
				const id = state.entity.data?.id;

				if (id) {
					const response = await ReleaseApi.publish({ id });
					if (response.id === state.entity.data?.id) {
						state.entity.data.publishedAt = response.publishedAt;
					}
				}
			} catch {
			} finally {
				state.entity.isPublishing = false;
			}
		},
	},
	selectors: {
		all: (state) => state.all,
		create: (state) => state.create,
		update: (state) => state.update,
		entity: (state) => state.entity,
	},
});
