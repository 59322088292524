import { useForm } from '../../core/domain/Form/useForm';
import { RequiredValidator } from '../../core/validators/Required.validator';
import { MaxLengthValidator } from '../../core/validators/MaxLength.validator';
import { useParams } from 'react-router';
import { ReleaseViewWithAudioFileModel } from '../../core/domain/Release/Release.type';
import { EpisodeStore } from '../../stores/episode';

function fromEntityToForm(data?: ReleaseViewWithAudioFileModel | null): Partial<EpisodeForm> {
	if (!data) {
		return {};
	}

	return {
		title: data.title,
		description: data.description,
		explicit: data.explicit,
		audio: data.getAudio() ?? undefined,
		needPublish: data.publishedAt ? null : false,
	};
}

type EpisodeForm = {
	title: string;
	description: string;
	explicit: boolean;
	audio: File;
	needPublish: boolean | null;
};
export const useEpisodeFormScreenBase = () => {
	const { episodeId, podcastId } = useParams();
	const isEditing = !!episodeId;
	const { create, update } = EpisodeStore.useActions();
	const { errorCode } = EpisodeStore.useSelector(isEditing ? 'update' : 'create');

	const { data } = EpisodeStore.useSelector('entity');

	const { isSubmitting, errors, values, getChangeHandler, submitHandler } = useForm<EpisodeForm>({
		validators: {
			title: [new RequiredValidator()],
			description: [new RequiredValidator(), new MaxLengthValidator(1000)],
			audio: [new RequiredValidator()],
		},
		initialState: {
			explicit: false,
			needPublish: false,
			...(isEditing ? fromEntityToForm(data) : {}),
		},
		onSubmit: async (values) => {
			if (podcastId) {
				const audioContext = new AudioContext();
				const buffer = await audioContext.decodeAudioData(await values.audio.arrayBuffer());
				const needPublish = values.needPublish === null || values.needPublish;
				if (isEditing) {
					await update({
						id: episodeId,
						channelId: podcastId,
						duration: buffer.duration,
						...values,
						needPublish,
					});
				} else {
					await create({ ...values, channelId: podcastId, duration: buffer.duration, needPublish });
				}
			}
		},
	});

	return {
		errors,
		values,
		getChangeHandler,
		submitHandler,
		errorCode,
		isSubmitting,
		isEditing,
	};
};
