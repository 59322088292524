import { HttpStatusCode, isAxiosError } from 'axios';
import { ServerErrorCode } from './ServerError.constant';
import { ServerErrorUtil } from './ServerError.util';
import { IServerError } from './ServerError.interface';

export class ServerError extends Error implements IServerError {
	code: ServerErrorCode = ServerErrorCode.INTERNAL_SERVER_ERROR;
	status: HttpStatusCode = HttpStatusCode.InternalServerError;

	constructor(error: unknown) {
		super(error instanceof Error ? error.message : 'Unknown error');

		if (isAxiosError(error)) {
			if (error.response?.data && ServerErrorUtil.is(error.response.data)) {
				this.code = error.response.data.code;
				this.status = error.response.data.status;
			}
		}

		if (ServerErrorUtil.is(error)) {
			this.code = error.code;
			this.status = error.status;
		}
	}
}
