import * as React from 'react';
import { createContext, PropsWithChildren, ReactNode, useCallback, useContext, useState } from 'react';
import { Snackbar } from '@vkontakte/vkui';

type SnackbarParams = {
	action?: React.ReactNode;
	onActionClick?: (e: React.MouseEvent) => void;
	before?: React.ReactNode;
	after?: React.ReactNode;
	layout?: 'vertical' | 'horizontal';
	duration?: number;
	mode?: 'default' | 'dark';
	subtitle?: React.ReactNode;
};

type SnackbarContextValue = (text: ReactNode, params?: SnackbarParams) => void;

const SnackbarContext = createContext<SnackbarContextValue>(() => {});

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = (props: PropsWithChildren) => {
	const { children } = props;
	const [snackbar, setSnackbar] = useState<ReactNode>(null);

	const closeHandler = useCallback(() => {
		setSnackbar(null);
	}, []);

	const open: SnackbarContextValue = useCallback(
		(text, params) => {
			setSnackbar(
				<Snackbar onClose={closeHandler} {...params}>
					{text}
				</Snackbar>,
			);
		},
		[closeHandler],
	);

	return (
		<SnackbarContext.Provider value={open}>
			{children}
			{snackbar}
		</SnackbarContext.Provider>
	);
};
