import { IFormValidator } from '../domain/Form/Form.type';

export class UsernameValidator implements IFormValidator<string, any> {
	validate = (params: { value: string | undefined }) => {
		const re = /^[\w\d_]+$/gi;
		if (!params.value) {
			return;
		}

		if (!re.test(params.value)) {
			return 'Имя пользователя может содержать только буквы, цифры и "_".';
		}
	};
}
