import { IFormValidator } from '../domain/Form/Form.type';

export class MaxLengthValidator implements IFormValidator<string, any> {
	protected maxLength: number;
	constructor(maxLength: number) {
		this.maxLength = maxLength;
	}
	validate = (params: { value: string | undefined }) => {
		if (!params.value) {
			return;
		}

		if (params.value.length > this.maxLength) {
			return `Максимальное допустимое количество символов: ${this.maxLength}`;
		}

		return;
	};
}
