import { Layout } from '../../components/Layout';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EmailConfirmApi } from '../../api/emailConfirm.api';
import { useSearchParams } from 'react-router-dom';
import { Placeholder, Spinner } from '@vkontakte/vkui';
import { Icon56CancelCircleOutline, Icon56CheckCircleOutline } from '@vkontakte/icons';
import css from './EmailConfirmedScreen.css';

export const EmailConfirmedScreen = () => {
	const [search] = useSearchParams();
	const [error, setError] = useState<string | null>(null);
	const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

	const confirmEmail = useCallback(async () => {
		try {
			const token = search.get('token');
			if (!token) {
				throw new Error('Неверный токен');
			}

			await EmailConfirmApi.confirm({ token });
			setIsEmailConfirmed(true);
		} catch (error: any) {
			setError(error.toString());
		}
	}, [search]);

	useEffect(() => {
		confirmEmail();
	}, [confirmEmail]);

	const errorNode = useMemo(() => {
		return (
			<Placeholder icon={<Icon56CancelCircleOutline className={css.errorIcon} />} header="Произошла ошибка">
				{error}
			</Placeholder>
		);
	}, [error]);

	const fetchingNode = useMemo(() => {
		return (
			<Placeholder icon={<Spinner className={css.fetchingSpinner} size="large" />} header="Ждем...">
				Идет подтверждение вашей электронной почты
			</Placeholder>
		);
	}, []);

	const successNode = useMemo(() => {
		return (
			<Placeholder icon={<Icon56CheckCircleOutline className={css.successIcon} />} header="Успешно">
				Электронная почта подтверждена!
			</Placeholder>
		);
	}, []);

	const content = useMemo(() => {
		if (error) {
			return errorNode;
		}

		if (isEmailConfirmed) {
			return successNode;
		}

		return fetchingNode;
	}, [error, errorNode, fetchingNode, isEmailConfirmed, successNode]);

	return (
		<Layout className={css.root} title="Подтверждение регистрации" mode="content" header="Подтверждение регистрации">
			{content}
		</Layout>
	);
};
