import { MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import { Cell, Image } from '@vkontakte/vkui';
import { Icon2418CircleOutline, Icon24PlaylistOutline } from '@vkontakte/icons';
import css from './PodcastCell.css';
import clsx from 'clsx';

export type PodcastCellProps = PropsWithChildren<{
	description?: ReactNode;
	imageSrc?: string | null;
	explicit?: boolean;
	episodesCount?: number;
	onClick?: MouseEventHandler<HTMLElement>;
	isRemoved?: boolean;
}>;

export const PodcastCell = (props: PodcastCellProps) => {
	const { description, children, imageSrc, explicit, episodesCount, onClick, isRemoved } = props;

	return (
		<Cell
			className={clsx(css.root, { [css.root_removed]: isRemoved })}
			before={<Image src={imageSrc ?? ''} />}
			subtitle={description}
			onClick={onClick}
			badgeAfterTitle={explicit && <Icon2418CircleOutline />}
			after={
				<div className={css.episodesCount}>
					{episodesCount} <Icon24PlaylistOutline />
				</div>
			}
		>
			{children}
		</Cell>
	);
};
