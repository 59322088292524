import { Layout } from '../../components/Layout';
import css from './LoginScreen.css';
import { Button, ButtonGroup, FormItem, FormLayout, FormStatus, Title } from '@vkontakte/vkui';
import podcastImgUrl from '../../assets/images/podcast.jpg';
import { PasswordField } from '../../components/form/PasswordField';
import { InputField } from '../../components/form/InputField';
import { useLoginScreenBase } from './useLoginScreenBase';

export const LoginScreen = () => {
	const { values, getChangeHandler, submitHandler, isLoginFetching, errorDescription } = useLoginScreenBase();

	return (
		<Layout className={css.root} mode="content" title="Авторизация">
			<div className={css.content}>
				<div className={css.imageContainer}>
					<img alt="beautiful-image" className={css.image} src={podcastImgUrl} />
				</div>
				<div className={css.formContainer}>
					<FormLayout onSubmit={submitHandler}>
						<FormItem>
							<Title level="1">Авторизация</Title>
						</FormItem>
						{errorDescription && <FormStatus mode="error">{errorDescription}</FormStatus>}
						<InputField
							value={values['username']}
							onChange={getChangeHandler('username')}
							name="username"
							autoComplete="on"
							label="Имя пользователя"
							placeholder="Введите имя пользователя"
						/>
						<PasswordField
							value={values['password']}
							onChange={getChangeHandler('password')}
							name="password"
							autoComplete="on"
							label="Пароль"
							placeholder="Введите пароль"
						/>
						<FormItem>
							<ButtonGroup>
								<Button type="submit" loading={isLoginFetching}>
									Войти
								</Button>
								{/* <LinkButton mode="tertiary" loading={isLoginFetching} to={ScreenPath.REGISTER}>
									Зарегистрироваться
								</LinkButton> */}
							</ButtonGroup>
						</FormItem>
					</FormLayout>
				</div>
			</div>
		</Layout>
	);
};
