import { ChannelViewModel } from '../../core/domain/Channel/Channel.type';
import { Div, Image, InfoRow, Link, RichCell, Spacing, Title } from '@vkontakte/vkui';
import css from './PodcastInfo.css';
import { Icon2418CircleOutline } from '@vkontakte/icons';
import clsx from 'clsx';
import { useMemo } from 'react';
import { UserStore } from '../../stores/user';

export type PodcastInfoProps = {
	data: ChannelViewModel;
	className?: string;
};

export const PodcastInfo = (props: PodcastInfoProps) => {
	const { data, className } = props;
	const currentUserId = UserStore.useSelector('id');
	const isCurrentUserOwner = currentUserId === data.createdById;

	const after = useMemo(() => {
		return data.removed ? 'Удален' : undefined;
	}, [data.removed]);
	const subhead = useMemo(() => {
		const publicationDate = new Date(data.publishedAt);

		return publicationDate.toLocaleDateString('ru', {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		});
	}, [data.publishedAt]);

	const text = useMemo(() => {
		return (
			<div>
				<Spacing size={12} />
				<InfoRow header="Автор">{data.author}</InfoRow>
				<Spacing />
				<InfoRow header="Категория">{data.category.label}</InfoRow>
				{isCurrentUserOwner && (
					<>
						<Spacing />
						<InfoRow header="Владелец">
							<Link href={`mailto:${data.owner}`} target="_blank">
								{data.owner}
							</Link>
						</InfoRow>
						<Spacing />
						<InfoRow header="Страница">
							<Link href={data.link} target="_blank">
								{data.link}
							</Link>
						</InfoRow>
					</>
				)}
			</div>
		);
	}, [data.author, data.category.label, data.link, data.owner, isCurrentUserOwner]);

	return (
		<div className={clsx(css.root, className)}>
			<Div className={css.mainInfo}>
				<div className={css.logoContainer}>
					<Image size={100} src={data.imageUrl ?? ''} />
					<Spacing size={20} />
				</div>
				<RichCell
					className={css.cell}
					subhead={subhead}
					disabled
					text={text}
					after={after}
					afterCaption={data.explicit && <Icon2418CircleOutline />}
				>
					<Title className={css.title} level="2">
						{data.title}
					</Title>
				</RichCell>
			</Div>
			<Spacing size={12} />
			<Div className={css.description}>{data.description}</Div>
		</div>
	);
};
