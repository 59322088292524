import {
	ChannelCreateRequestModel,
	ChannelCreateViewModel,
	ChannelResponseModel,
	ChannelUpdateRequestModel,
	ChannelUpdateViewModel,
	ChannelViewModel,
	ChannelWithLogoFileViewModel,
} from './Channel.type';
import { getFileByUrl } from '../../../util/getFileByUrl';
import { PodcastStore } from '../../../stores/podcast';
import { Option } from '../Option/Option.type';
import { ChannelCategory } from './Channel.constant';

const Create = {
	fromViewToRequest: (data: ChannelCreateViewModel): ChannelCreateRequestModel => {
		return {
			...data,
			category: data.category.value,
		};
	},
};

const Update = {
	fromViewToRequest: (data: ChannelUpdateViewModel): ChannelUpdateRequestModel => {
		return {
			...data,
			category: data.category.value,
		};
	},
};

const Entity = {
	fromResponseToView: async (data: ChannelResponseModel): Promise<ChannelViewModel> => {
		const categories = PodcastStore.store.get().category.data;

		return {
			...data,
			category: (categories?.find((item) => item.value === data.category) as Option<ChannelCategory>) ?? {
				label: '',
				value: data.category,
			},
		};
	},
	fromResponseToViewWithLogo: async (data: ChannelResponseModel): Promise<ChannelWithLogoFileViewModel> => {
		const logo = data.imageUrl ? await getFileByUrl(data.imageUrl) : null;
		const categories = PodcastStore.store.get().category.data;

		return {
			...data,
			category: (categories?.find((item) => item.value === data.category) as Option<ChannelCategory>) ?? {
				label: '',
				value: data.category,
			},
			getLogo: () => logo,
		};
	},
};

export const ChannelConverter = {
	Create,
	Entity,
	Update,
};
