import { Layout } from '../../components/Layout';
import { useSearchParams } from 'react-router-dom';
import { Accordion, Button, Div, Group, Header, Link, Separator, Spacing, Title } from '@vkontakte/vkui';
import { useCallback, useState } from 'react';
import { Icon20CheckSmallOutline } from '@vkontakte/icons';
import { UserStore } from '../../stores/user';

export const ConfirmEmailScreen = () => {
	const [search] = useSearchParams();
	const [isResending, setIsResending] = useState(false);
	const [isResendSuccess, setIsResendSuccess] = useState(false);
	const { resendConfirmEmail } = UserStore.useActions();

	const resendClickHandler = useCallback(async () => {
		try {
			setIsResending(true);
			setIsResendSuccess(false);
			const userId = search.get('userId');
			if (userId) {
				await resendConfirmEmail(userId);
				setIsResendSuccess(true);
			}
		} finally {
			setIsResending(false);
		}
	}, [resendConfirmEmail, search]);

	return (
		<Layout mode="content" title="Подтвердите электронную почту" header="Подтвердите электронную почту">
			<Div>
				Почти все! На вашу электронную почту [<Link href={`mailto:${search.get('email')}`}>{search.get('email')}</Link>]
				отправлено письмо с ссылкой для подтверждения регистрации.
			</Div>
			<Spacing size={20}>
				<Separator />
			</Spacing>
			<Accordion>
				<Accordion.Summary>Письмо не пришло?</Accordion.Summary>
				<Group
					header={
						<Header>
							<Title level="2">Спам</Title>
						</Header>
					}
					mode="plain"
				>
					<Div>Проверьте папку спам. Из-за систем автофильтрации письма часто попадают именно туда.</Div>
				</Group>
				<Group
					header={
						<Header>
							<Title level="2">Рассылка</Title>
						</Header>
					}
					mode="plain"
				>
					<Div>
						Возможно ваш почтовый оператор посчитал наше письмо частью рассылки. В настройках необходимо подтвердить наш
						электронный адрес для получения рассылки.
					</Div>
				</Group>
				<Group
					header={
						<Header>
							<Title level="2">Не получилось отправить</Title>
						</Header>
					}
					mode="plain"
				>
					<Div>Возможно на нашей стороне произошла ошибка, попробуйте снова.</Div>
					<Div>
						<Button
							before={isResendSuccess && <Icon20CheckSmallOutline />}
							loading={isResending}
							size="l"
							onClick={resendClickHandler}
						>
							Отправить письмо заново
						</Button>
					</Div>
				</Group>
			</Accordion>
		</Layout>
	);
};
