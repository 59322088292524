import createHookStore from 'starux/react';
import { AppStoreState } from './app.type';
import { Appearance } from '@vkontakte/vkui';
import { UserStore } from '../user';

const initialState: AppStoreState = {
	appearance: undefined,
	isInitialized: false,
};

export const AppStore = createHookStore({
	initialState,
	reducers: {
		setAppearance: (state, appearance: Appearance) => {
			state.appearance = appearance;
		},
		initialize: async (state) => {
			if (!state.isInitialized) {
				await UserStore.store.actions.fetchCurrentUser();
				state.isInitialized = true;
			}
		},
	},
	selectors: {
		appearance: (state) => state.appearance,
	},
});
