import { PodcastStore } from '../../stores/podcast';
import { useCallback, useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router';
import { ScreenPath } from '../../constant/screen';

export const usePodcastListScreenBase = () => {
	const { isFetching, isAllFetched, data, errorCode } = PodcastStore.useSelector('all');
	const { fetchAll } = PodcastStore.useActions();
	const navigate = useNavigate();

	const getCellClickHandler = useCallback(
		(id: string) => () => {
			navigate(generatePath(ScreenPath.PODCAST_VIEW, { podcastId: id }));
		},
		[navigate],
	);

	useEffect(() => {
		fetchAll(0);
	}, [fetchAll]);

	return {
		isFetching,
		isAllFetched,
		data,
		errorCode,
		fetchAll,
		getCellClickHandler,
	};
};
