import { Layout } from '../../components/Layout';
import { Spacing, Text, Title } from '@vkontakte/vkui';
import css from './MainScreen.css';
import { LinkButton } from '../../components/LinkButton';
import { ScreenPath } from '../../constant/screen';

export const MainScreen = () => {
	return (
		<Layout className={css.root} mode="fullscreen" title="Добро пожаловать">
			<div className={css.content}>
				<Title level="1" className={css.title}>
					Добро пожаловать на
				</Title>
				<div className={css.site}>podcast.paprikamedia.ru</div>
				<Spacing size={50} />
				<LinkButton className={css.button} size="l" to={ScreenPath.PODCASTS}>
					<Text className={css.button__text}>Перейти к подкастам</Text>
				</LinkButton>
			</div>
		</Layout>
	);
};
