import { MouseEventHandler, PropsWithChildren, ReactNode, useMemo } from 'react';
import { Image, RichCell } from '@vkontakte/vkui';
import { Icon2418CircleOutline } from '@vkontakte/icons';
import css from './EpisodeCell.css';
import { AudioPlayer } from '../AudioPlayer';
import clsx from 'clsx';

export type EpisodeCellProps = PropsWithChildren<{
	description?: ReactNode;
	imageSrc?: string;
	explicit?: boolean;
	num: number;
	publishedAt?: number;
	audioSrc?: string | null;
	onClick?: MouseEventHandler<HTMLElement>;
	isRemoved?: boolean;
}>;

export const EpisodeCell = (props: EpisodeCellProps) => {
	const { description, children, imageSrc, explicit, num, publishedAt, audioSrc, onClick, isRemoved } = props;

	const published = useMemo(
		() =>
			publishedAt
				? new Date(publishedAt).toLocaleDateString('ru', {
						year: 'numeric',
						month: 'long',
						day: 'numeric',
				  })
				: 'Не опубликован',
		[publishedAt],
	);

	return (
		<RichCell
			className={clsx(css.root, { [css.root_removed]: isRemoved })}
			before={
				<Image src={imageSrc}>
					<Image.Badge>
						<div className={css.num}>{num}</div>
					</Image.Badge>
				</Image>
			}
			caption={description}
			subhead={published}
			onClick={onClick}
			afterCaption={explicit && <Icon2418CircleOutline />}
			bottom={<AudioPlayer src={audioSrc} />}
		>
			{children}
		</RichCell>
	);
};
