import {
	ChannelCreateRequestModel,
	ChannelCreateResponseModel,
	ChannelDeleteRequestModel,
	ChannelDeleteResponseModel,
	ChannelGetAllRequestModel,
	ChannelGetAllResponseModel,
	ChannelGetRequestModel,
	ChannelGetRSSLinkRequestModel,
	ChannelResponseModel,
	ChannelRestoreRequestModel,
	ChannelRestoreResponseModel,
	ChannelUpdateRequestModel,
	ChannelUpdateResponseModel,
} from '../core/domain/Channel/Channel.type';
import { ApiService } from './api.service';
import { OptionResponseModel } from '../core/domain/Option/Option.type';
import { toFormData } from 'axios';

export const ChannelApi = {
	getRSSLink: (data: ChannelGetRSSLinkRequestModel) => {
		return ApiService.getUrl(`/channel/rss/${data.id}.xml`);
	},
	create: (data: ChannelCreateRequestModel) => {
		return ApiService.post<ChannelCreateResponseModel>('/channel/create', toFormData(data));
	},
	update: (data: ChannelUpdateRequestModel) => {
		const { id, ...requestData } = data;

		return ApiService.post<ChannelUpdateResponseModel>(`/channel/${id}/update`, toFormData(requestData));
	},
	getAll: (data: ChannelGetAllRequestModel) => {
		return ApiService.get<ChannelGetAllResponseModel>('/channel/all/current', { params: data });
	},
	getCategories: () => {
		return ApiService.get<OptionResponseModel[]>('/channel/category/select');
	},
	get: (data: ChannelGetRequestModel) => {
		return ApiService.get<ChannelResponseModel>(`/channel/${data.id}`);
	},
	delete: (data: ChannelDeleteRequestModel) => {
		return ApiService.post<ChannelDeleteResponseModel>(`/channel/${data.id}/delete`);
	},
	restore: (data: ChannelRestoreRequestModel) => {
		return ApiService.post<ChannelRestoreResponseModel>(`/channel/${data.id}/restore`);
	},
};
