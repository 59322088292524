import { IFormValidator } from '../domain/Form/Form.type';

export class PasswordValidator implements IFormValidator<string, any> {
	validate = (params: { value: string | undefined }) => {
		if (!params.value) {
			return;
		}

		if (params.value.length < 8) {
			return 'Пароль должен содержать как минимум 8 символов';
		}
		if (params.value.search(/[a-z]/i) < 0) {
			return 'Пароль должен состоять как минимум из 1 буквы';
		}
		if (params.value.search(/[0-9]/) < 0) {
			return 'Пароль должен содержать как минимум 1 цифру';
		}
	};
}
