import createHookStore from 'starux/react';
import { UserStoreState } from './user.type';
import { UserApi } from '../../api/user.api';
import { UserStatus } from '../../core/domain/User/User.constant';
import { EmailConfirmApi } from '../../api/emailConfirm.api';

const initialState: UserStoreState = {
	current: null,
	status: UserStatus.UNKNOWN,
};
export const UserStore = createHookStore({
	initialState,
	reducers: {
		fetchCurrentUser: async (state) => {
			try {
				const response = await UserApi.getCurrentUser();
				state.current = response;
				state.status = response.isEmailVerified ? UserStatus.VERIFIED : UserStatus.UNVERIFIED;
			} catch (e) {
				state.status = UserStatus.UNAUTHORIZED;
			}
		},
		resendConfirmEmail: async (_, userId: string) => {
			await EmailConfirmApi.resendById({ id: userId });
			await UserStore.store.actions.fetchCurrentUser();
		},
		clearUserData: (state) => {
			state.status = UserStatus.UNAUTHORIZED;
			state.current = null;
		},
	},
	selectors: {
		isAuthorized: (state) => !!state.current,
		status: (state) => state.status,
		username: (state) => state.current?.username,
		email: (state) => state.current?.email,
		id: (state) => state.current?.id,
	},
});
