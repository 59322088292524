import { ReactComponent as AppLogo } from '../../assets/images/logo.svg';
import css from './Header.css';
import { Appearance, IconButton, useAppearance } from '@vkontakte/vkui';
import { Icon24Moon, Icon24SunOutline } from '@vkontakte/icons';
import { AppStore } from '../../stores/app';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ScreenPath } from '../../constant/screen';
import { UserStore } from '../../stores/user';
import { UserDropdown } from '../UserDropdown';
import { LinkButton } from '../LinkButton';

export const Header = () => {
	const appearance = useAppearance();
	const { setAppearance } = AppStore.useActions();
	const isAuthorized = UserStore.useSelector('isAuthorized');

	const themeButtonClickHandler = useCallback(() => {
		if (appearance === 'dark') {
			setAppearance(Appearance.LIGHT);
		} else {
			setAppearance(Appearance.DARK);
		}
	}, [setAppearance, appearance]);

	return (
		<div className={css.root}>
			<div className={css.inner}>
				<div className={css.inner__before}>
					<Link to={ScreenPath.MAIN}>
						<AppLogo />
					</Link>
				</div>
				<div className={css.inner__content}>
					паприка{' '}
					{isAuthorized ? (
						<>
							| <UserDropdown />
						</>
					) : undefined}
				</div>
				<div className={css.inner__after}>
					{isAuthorized && <LinkButton to={ScreenPath.PODCAST_CREATE}>Создать подкаст</LinkButton>}
					<IconButton onClick={themeButtonClickHandler}>
						{appearance === Appearance.DARK ? <Icon24Moon /> : <Icon24SunOutline />}
					</IconButton>
				</div>
			</div>
		</div>
	);
};
