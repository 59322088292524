import { Layout } from '../../components/Layout';
import { PodcastCell } from '../../components/PodcastCell';
import { InfinityScrollContainer } from '../../components/InfinityScrollContainer';
import { usePodcastListScreenBase } from './usePodcastListScreenBase';
import { FormStatus, Placeholder } from '@vkontakte/vkui';
import { LinkButton } from '../../components/LinkButton';
import { ScreenPath } from '../../constant/screen';
import { Icon24AddSquareOutline } from '@vkontakte/icons';

export const PodcastListScreen = () => {
	const { isAllFetched, data, errorCode, fetchAll, getCellClickHandler, isFetching } = usePodcastListScreenBase();

	return (
		<Layout
			title="Ваши подкасты"
			header="Ваши подкасты"
			action={
				<LinkButton mode="link" to={ScreenPath.PODCAST_CREATE}>
					<Icon24AddSquareOutline />
				</LinkButton>
			}
		>
			<InfinityScrollContainer onMarkerIntersect={fetchAll} hideMarker={isAllFetched}>
				{data.map((podcast) => (
					<PodcastCell
						isRemoved={podcast.removed}
						key={podcast.id}
						imageSrc={podcast.imageUrl}
						description={podcast.description}
						explicit={podcast.explicit}
						episodesCount={podcast.releaseCount}
						onClick={getCellClickHandler(podcast.id)}
					>
						{podcast.title}
					</PodcastCell>
				))}
				{!data.length && !errorCode && !isFetching && (
					<Placeholder header="Пусто">Вы еще не создали ни одного подкаста :(</Placeholder>
				)}
				{!!errorCode && (
					<FormStatus mode="error" header="Произошла ошибка">
						Попробуйте позже
					</FormStatus>
				)}
			</InfinityScrollContainer>
		</Layout>
	);
};
