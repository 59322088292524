import createHookStore from 'starux/react';
import { PodcastStoreState } from './podcast.type';
import { ChannelApi } from '../../api/channel.api';
import { AppError } from '../../core/domain/AppError/AppError';
import { ChannelCreateViewModel, ChannelUpdateViewModel } from '../../core/domain/Channel/Channel.type';
import { ChannelConverter } from '../../core/domain/Channel/Channel.converter';
import { createQueue } from 'starux/middlewares';
import { navigate } from '../../components/AppRouter';
import { generatePath } from 'react-router';
import { ScreenPath } from '../../constant/screen';
import { OptionConverter } from '../../core/domain/Option/Option.converter';
import { UserStore } from '../user';
import { UserStatus } from '../../core/domain/User/User.constant';

const initialState: PodcastStoreState = {
	all: {
		data: [],
		page: -1,
		size: 30,
		isFetching: false,
		errorCode: null,
		total: 0,
		isAllFetched: false,
	},
	category: {
		data: null,
		isFetching: false,
		errorCode: null,
	},
	create: {
		isFetching: false,
		errorCode: null,
	},
	update: {
		isFetching: false,
		errorCode: null,
	},
	entity: {
		isFetching: false,
		isDeleting: false,
		isRestoring: false,
		errorCode: null,
		data: null,
	},
};
export const PodcastStore = createHookStore({
	initialState,
	reducers: {
		fetchAll: createQueue(async (state: PodcastStoreState, page?: number) => {
			const newPage = page ?? state.all.page + 1;

			if ((!state.all.isAllFetched || page !== undefined) && !state.all.isFetching) {
				try {
					state.all.isFetching = true;
					state.all.errorCode = null;

					const response = await ChannelApi.getAll({
						page: newPage,
						size: state.all.size,
					});

					state.all.page = newPage;
					state.all.total = response.total;
					const newData = await Promise.all(response.data.map(ChannelConverter.Entity.fromResponseToView));
					state.all.data = newPage === 0 ? newData : [...state.all.data, ...newData];
					state.all.isAllFetched = response.count === 0;
				} catch (error) {
					state.all.errorCode = new AppError(error).code;
				} finally {
					state.all.isFetching = false;
				}
			}
		}),
		fetchCategories: async (state) => {
			try {
				state.category.isFetching = true;
				state.category.errorCode = null;
				state.category.data = (await ChannelApi.getCategories()).map(OptionConverter.fromResponseToView);
			} catch (error) {
				state.category.errorCode = new AppError(error).code;
			} finally {
				state.category.isFetching = false;
			}
		},
		create: async (state, values: ChannelCreateViewModel) => {
			try {
				state.create.isFetching = true;
				state.create.errorCode = null;
				const response = await ChannelApi.create(ChannelConverter.Create.fromViewToRequest(values));
				navigate(generatePath(ScreenPath.PODCAST_VIEW, { podcastId: response.id }));
			} catch (error) {
				state.create.errorCode = new AppError(error).code;
			} finally {
				state.create.isFetching = false;
			}
		},
		update: async (state, values: ChannelUpdateViewModel) => {
			try {
				state.update.isFetching = true;
				state.update.errorCode = null;
				const response = await ChannelApi.update(ChannelConverter.Update.fromViewToRequest(values));
				navigate(generatePath(ScreenPath.PODCAST_VIEW, { podcastId: response.id }));
			} catch (error) {
				state.update.errorCode = new AppError(error).code;
			} finally {
				state.update.isFetching = false;
			}
		},
		get: async (state, id: string) => {
			try {
				state.entity.isFetching = true;
				state.entity.errorCode = null;
				state.entity.data = await ChannelConverter.Entity.fromResponseToViewWithLogo(await ChannelApi.get({ id }));
			} catch (error) {
				state.entity.errorCode = new AppError(error).code;
				throw error;
			} finally {
				state.entity.isFetching = false;
			}
		},
		remove: async (state) => {
			try {
				state.entity.isDeleting = true;
				const id = state.entity.data?.id;

				if (id) {
					const response = await ChannelApi.delete({ id });
					if (response.id === state.entity.data?.id) {
						state.entity.data.removed = true;
					}
				}
			} catch {
			} finally {
				state.entity.isDeleting = false;
			}
		},
		restore: async (state) => {
			try {
				state.entity.isRestoring = true;
				const id = state.entity.data?.id;

				if (id) {
					const response = await ChannelApi.restore({ id });
					if (response.id === state.entity.data?.id) {
						state.entity.data.removed = false;
					}
				}
			} catch {
			} finally {
				state.entity.isRestoring = false;
			}
		},
	},
	selectors: {
		all: (state) => state.all,
		category: (state) => state.category,
		create: (state) => state.create,
		update: (state) => state.update,
		entity: (state) => state.entity,
	},
});

UserStore.store.subscribe((newState) => {
	const categoryState = PodcastStore.store.get.category();
	if (newState.status === UserStatus.VERIFIED && !categoryState.data?.length && !categoryState.isFetching) {
		PodcastStore.store.actions.fetchCategories();
	}
});
