import {
	ReleaseCreateRequestModel,
	ReleaseCreateViewModel,
	ReleaseResponseModel,
	ReleaseUpdateRequestModel,
	ReleaseUpdateViewModel,
	ReleaseViewModel,
	ReleaseViewWithAudioFileModel,
} from './Release.type';
import { getFileByUrl } from '../../../util/getFileByUrl';

const Create = {
	fromViewToRequest(data: ReleaseCreateViewModel): ReleaseCreateRequestModel {
		return {
			description: data.description,
			audio: data.audio,
			title: data.title,
			explicit: data.explicit,
			channelId: data.channelId,
			needPublish: data.needPublish,
			duration: Math.ceil(data.duration),
		};
	},
};

const Update = {
	fromViewToRequest(data: ReleaseUpdateViewModel): ReleaseUpdateRequestModel {
		return {
			id: data.id,
			description: data.description,
			audio: data.audio,
			title: data.title,
			explicit: data.explicit,
			channelId: data.channelId,
			needPublish: data.needPublish,
			duration: Math.ceil(data.duration),
		};
	},
};

const Entity = {
	async fromResponseToView(data: ReleaseResponseModel): Promise<ReleaseViewModel> {
		return {
			id: data.id,
			title: data.title,
			audioUrl: data.audioUrl,
			imageUrl: data.imageUrl,
			description: data.description,
			publishedAt: data.publishedAt,
			createdAt: data.createdAt,
			explicit: data.explicit,
			removed: data.removed,
			channelId: data.channelId,
			createdById: data.createdById,
		};
	},
	async fromResponseToViewWithAudio(data: ReleaseResponseModel): Promise<ReleaseViewWithAudioFileModel> {
		const audio = data.audioUrl ? await getFileByUrl(data.audioUrl) : null;

		return {
			id: data.id,
			title: data.title,
			audioUrl: data.audioUrl,
			imageUrl: data.imageUrl,
			getAudio: () => audio,
			description: data.description,
			publishedAt: data.publishedAt,
			createdAt: data.createdAt,
			explicit: data.explicit,
			removed: data.removed,
			channelId: data.channelId,
			createdById: data.createdById,
		};
	},
};

export const ReleaseConverter = {
	Create,
	Update,
	Entity,
};
