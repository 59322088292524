import css from './AudioPlayer.css';
import { Button, Card, Div, Footnote, Progress, Spinner, unstable_Popover as Popover } from '@vkontakte/vkui';
import { Icon20Volume, Icon24Pause, Icon24Play } from '@vkontakte/icons';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

export type AudioPlayerProps = {
	className?: string;
	src?: string | null;
	disabled?: boolean;
};

export const AudioPlayer = (props: AudioPlayerProps) => {
	const { src, disabled, className } = props;
	const [currentTime, setCurrentTime] = useState(0);
	const [isPaused, setIsPaused] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [volume, setVolume] = useState(100);
	const [isVolumeControllerOpen, setIsVolumeControllerOpen] = useState(false);
	const [audio, setAudio] = useState<HTMLAudioElement>();

	const duration = useMemo(() => audio?.duration ?? 0, [audio?.duration]);

	const progress = useMemo(() => (duration ? (currentTime / duration) * 100 : 0), [currentTime, duration]);

	const loadAudio = useCallback((): Promise<HTMLAudioElement> => {
		return new Promise((resolve) => {
			if (!audio && src) {
				setIsLoading(true);
				const newAudio = new Audio(src);
				setAudio(newAudio);
				const listener = () => {
					resolve(newAudio);
					newAudio.removeEventListener('canplaythrough', listener);
				};
				newAudio.addEventListener('canplaythrough', listener);
				setIsLoading(false);

				return;
			}

			if (audio) {
				resolve(audio);
			}
		});
	}, [audio, src]);

	const playButtonClick = useCallback(
		async (event: MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			const audio = await loadAudio();

			if (audio.paused) {
				audio.play().then(() => {
					setIsPaused(false);
				});
			} else {
				setIsPaused(true);
				audio.pause();
			}
		},
		[loadAudio],
	);

	const progressClickHandler = useCallback(
		(event: MouseEvent<HTMLDivElement>) => {
			event.stopPropagation();
			if (audio) {
				const rect = event.currentTarget.getBoundingClientRect();
				const mouseX = Math.floor(event.pageX - rect.left);
				const progress = mouseX / (event.currentTarget.offsetWidth / 100);
				audio.currentTime = Math.min(Math.floor(audio.duration * (progress / 100)), audio.duration);
			}
		},
		[audio],
	);

	const volumeClickHandler = useCallback(
		(event: MouseEvent<HTMLDivElement>) => {
			if (audio) {
				const rect = event.currentTarget.getBoundingClientRect();
				const mouseX = Math.floor(event.clientY - rect.top);
				const progress = mouseX / (event.currentTarget.offsetHeight / 100);
				setVolume(Math.min(100 - progress, 100));
				audio.volume = Math.min(1 - progress / 100, 1);
			}
		},
		[audio],
	);

	useEffect(() => {
		if (audio) {
			const timeUpdateListener = () => {
				setCurrentTime(audio.currentTime);
			};

			audio.addEventListener('timeupdate', timeUpdateListener);

			return () => {
				audio.removeEventListener('timeupdate', timeUpdateListener);
			};
		}
	}, [audio]);

	const isAudioDisabled = disabled || !audio;

	const formattedCurrentTime = useMemo(() => {
		const date = new Date(0);
		date.setSeconds(Math.floor(currentTime || 0));

		return date.toISOString().substring(11, 19);
	}, [currentTime]);

	const formattedDuration = useMemo(() => {
		const date = new Date(0);
		date.setSeconds(Math.floor(duration || 0));

		return date.toISOString().substring(11, 19);
	}, [duration]);

	return (
		<div
			className={clsx(css.root, { [css.root_disabled]: isAudioDisabled }, className)}
			onClick={(event) => event.stopPropagation()}
		>
			<Button
				mode="link"
				appearance="accent"
				disabled={disabled}
				onClick={playButtonClick}
				stopPropagation
				className={css.control}
			>
				{isLoading ? <Spinner size="small" /> : isPaused ? <Icon24Play /> : <Icon24Pause />}
			</Button>
			<div className={css.progressContainer}>
				<Progress onClick={progressClickHandler} className={css.progress} value={progress} />
			</div>
			<Popover
				action="click"
				shown={isVolumeControllerOpen}
				onShownChange={setIsVolumeControllerOpen}
				placement="top"
				content={
					<Card mode="shadow">
						<Div className={css.volumeBlock}>
							<div className={css.volumeContainer} onClick={volumeClickHandler}>
								<div className={css.volume} style={{ height: `${volume}%` }} />
							</div>
						</Div>
					</Card>
				}
			>
				<Button stopPropagation mode="link" appearance="accent" disabled={isAudioDisabled}>
					<Icon20Volume />
				</Button>
			</Popover>
			<Footnote className={css.time}>
				{formattedCurrentTime} / {formattedDuration}
			</Footnote>
		</div>
	);
};
