export enum ServerErrorCode {
	NOT_FOUND = 'NOT_FOUND',
	UNAUTHORIZED = 'UNAUTHORIZED',
	INVALID_AUTH_DATA = 'INVALID_AUTH_DATA',
	INVALID_FILE_TYPE = 'INVALID_FILE_TYPE',
	INVALID_TOKEN = 'INVALID_TOKEN',
	BAD_REQUEST = 'BAD_REQUEST',
	ALREADY_EXIST = 'ALREADY_EXIST',
	ALREADY_DONE = 'ALREADY_DONE',
	TOKEN_EXPIRED = 'TOKEN_EXPIRED',
	INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
	FIELD_VALIDATION_ERROR = 'FIELD_VALIDATION_ERROR',
}
