import { useCallback } from 'react';
import { useForm } from '../../core/domain/Form/useForm';
import { AuthStore } from '../../stores/auth';
import { AppErrorCode } from '../../core/domain/AppError/AppError.type';
import { ServerErrorCode } from '../../core/domain/ServerError/ServerError.constant';
import { CommonErrorCode } from '../../core/domain/AppError/AppError.constant';
import { RequiredValidator } from '../../core/validators/Required.validator';
import { EmailValidator } from '../../core/validators/Email.validator';
import { UsernameValidator } from '../../core/validators/Username.validator';
import { PasswordValidator } from '../../core/validators/Password.validator';

export type RegistrationForm = {
	username: string;
	password: string;
	email: string;
};

function getErrorDescription(code: AppErrorCode | null) {
	if (!code) {
		return null;
	}

	switch (code) {
		case ServerErrorCode.ALREADY_EXIST:
			return 'Такой пользователь уже существует';
		case CommonErrorCode.APPLICATION:
		case ServerErrorCode.BAD_REQUEST:
			return 'Произошла ошибка на стороне приложения';
		default:
			return 'Произошла ошибка на стороне сервера';
	}
}

export const useRegistrationScreenBase = () => {
	const { register } = AuthStore.useActions();
	const registerErrorCode = AuthStore.useSelector('registerErrorCode');
	const isFetching = AuthStore.useSelector('isRegisterFetching');

	const onSubmit = useCallback(
		async (values: RegistrationForm) => {
			register(values);
		},
		[register],
	);

	const { values, errors, getChangeHandler, isValid, validating, submitHandler } = useForm<RegistrationForm>({
		validators: {
			email: [new RequiredValidator(), new EmailValidator()],
			username: [new RequiredValidator(), new UsernameValidator()],
			password: [new RequiredValidator(), new PasswordValidator()],
		},
		onSubmit,
	});

	return {
		validating,
		values,
		isValid,
		errors,
		getChangeHandler,
		submitHandler,
		requestErrorDescription: getErrorDescription(registerErrorCode),
		isFetching,
	};
};
