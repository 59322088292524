import { Option, OptionResponseModel } from './Option.type';

export const OptionConverter = {
	fromResponseToView(data: OptionResponseModel): Option {
		return {
			value: data.value,
			label: data.label,
		};
	},
};
