import css from './LoadingScreen.css';
import { animate, Placeholder, Spinner } from '@vkontakte/vkui';
import { useLayoutEffect } from 'react';
import { createRoot } from 'react-dom/client';

export const LoadingScreen = () => {
	useLayoutEffect(() => {
		const rootNode = document.createElement('div');
		rootNode.id = 'loading';
		rootNode.className = css.root;
		document.body.append(rootNode);

		const loadingRoot = createRoot(rootNode);

		loadingRoot.render(<Placeholder header="Загрузка" icon={<Spinner size="large" className={css.loading} />} />);

		return () => {
			animate({
				duration: 500,
				timing: (x) => x,
				draw: (progress) => {
					rootNode.style.opacity = (1 - (progress > 0.7 ? (progress - 0.7) / 0.7 : 0)).toString();

					if (progress >= 1) {
						loadingRoot.unmount();
						rootNode.remove();
					}
				},
			});
		};
	});

	return <></>;
};
