import { useCallback, useLayoutEffect, useMemo } from 'react';
import { PodcastStore } from '../../stores/podcast';
import { useNavigate, useParams } from 'react-router';
import { useSnackbar } from '../../context/snackbar.context';
import { Icon28CheckCircleOn } from '@vkontakte/icons';
import { copyTextToClipboard } from '../../util/copyTextToClipboard';
import { ChannelApi } from '../../api/channel.api';
import { EpisodeStore } from '../../stores/episode';
import { UserStore } from '../../stores/user';
import { generateUrl } from '../../util/generateUrl';
import { ScreenPath } from '../../constant/screen';

export const usePodcastViewScreenBase = () => {
	const { remove, restore } = PodcastStore.useActions();
	const {
		isFetching: isPodcastFetching,
		errorCode: podcastErrorCode,
		data: podcastData,
		isDeleting: isPodcastDeleting,
		isRestoring: isPodcastRestoring,
	} = PodcastStore.useSelector('entity');
	const {
		data: episodes,
		errorCode: episodeErrorCode,
		isFetching: isEpisodesFetching,
		isAllFetched: isAllEpisodesFetched,
		total: totalEpisodeCount,
	} = EpisodeStore.useSelector('all');
	const userId = UserStore.useSelector('id');
	const { fetchAll: fetchAllEpisodes } = EpisodeStore.useActions();

	const { podcastId } = useParams();
	const openSnackbar = useSnackbar();
	const navigate = useNavigate();

	const fetchNextEpisodes = useCallback(() => {
		if (podcastId) {
			fetchAllEpisodes(podcastId);
		}
	}, [fetchAllEpisodes, podcastId]);

	const copyRSSClickHandler = useCallback(() => {
		if (podcastId) {
			copyTextToClipboard(ChannelApi.getRSSLink({ id: podcastId }));
			openSnackbar('Ссылка скопирована!', { before: <Icon28CheckCircleOn /> });
		}
	}, [podcastId, openSnackbar]);

	const isOwner = useMemo(() => userId === podcastData?.createdById, [podcastData?.createdById, userId]);

	const getEpisodeClickHandler = useCallback(
		(id: string) => () => {
			if (podcastId) {
				navigate(generateUrl(ScreenPath.EPISODE_VIEW, { podcastId, episodeId: id }));
			}
		},
		[navigate, podcastId],
	);

	useLayoutEffect(() => {
		if (podcastId) {
			fetchAllEpisodes(podcastId, 0);
		}
	}, [fetchAllEpisodes, podcastId]);

	return {
		isPodcastFetching,
		podcastData,
		podcastErrorCode,
		copyRSSClickHandler,
		isPodcastDeleting,
		isPodcastRestoring,
		remove,
		restore,
		episodes,
		episodeErrorCode,
		isAllEpisodesFetched,
		isEpisodesFetching,
		fetchNextEpisodes,
		isOwner,
		getEpisodeClickHandler,
		totalEpisodeCount,
	};
};
