import { AdaptivityProvider, ConfigProvider } from '@vkontakte/vkui';
import { PropsWithChildren } from 'react';
import { AppStore } from '../../stores/app';
import { AppRouter } from '../AppRouter';
import { SnackbarProvider } from '../../context/snackbar.context';

export const Providers = (props: PropsWithChildren) => {
	const appearance = AppStore.useSelector('appearance');

	return (
		<AppRouter>
			<ConfigProvider appearance={appearance}>
				<AdaptivityProvider>
					<SnackbarProvider>{props.children}</SnackbarProvider>
				</AdaptivityProvider>
			</ConfigProvider>
		</AppRouter>
	);
};
