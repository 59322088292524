import { useForm } from '../../core/domain/Form/useForm';
import { useCallback } from 'react';
import { AuthStore } from '../../stores/auth';
import { ServerErrorCode } from '../../core/domain/ServerError/ServerError.constant';
import { AppErrorCode } from '../../core/domain/AppError/AppError.type';
import { CommonErrorCode } from '../../core/domain/AppError/AppError.constant';

type LoginForm = {
	username: string;
	password: string;
};

function getErrorDescription(code: AppErrorCode | null) {
	if (!code) {
		return null;
	}

	switch (code) {
		case ServerErrorCode.INVALID_AUTH_DATA:
			return 'Неверный логин или пароль';
		case CommonErrorCode.APPLICATION:
		case ServerErrorCode.BAD_REQUEST:
			return 'Произошла ошибка на стороне приложения';
		default:
			return 'Произошла ошибка на стороне сервера';
	}
}

export const useLoginScreenBase = () => {
	const { login } = AuthStore.useActions();
	const loginErrorCode = AuthStore.useSelector('loginErrorCode');
	const isLoginFetching = AuthStore.useSelector('isLoginFetching');
	const onSubmit = useCallback(
		async (values: LoginForm) => {
			await login(values);
		},
		[login],
	);
	const { getChangeHandler, values, submitHandler } = useForm({
		onSubmit,
	});

	return {
		getChangeHandler,
		values,
		submitHandler,
		isLoginFetching,
		errorDescription: getErrorDescription(loginErrorCode),
	};
};
