import { InputField, InputFieldProps } from '../InputField';
import { useCallback, useMemo, useState } from 'react';
import { Icon12EyeSlashOutline, Icon20View } from '@vkontakte/icons';
import { IconButton } from '@vkontakte/vkui';

export type PasswordFieldProps = Omit<InputFieldProps, 'type'>;

export const PasswordField = (props: PasswordFieldProps) => {
	const { after: externalAfter, ...inputProps } = props;
	const [isPasswordHidden, setIsPasswordHidden] = useState(true);
	const toggleIsPasswordHidden = useCallback(() => {
		setIsPasswordHidden((prev) => !prev);
	}, []);

	const after = useMemo(() => {
		return (
			<div>
				{externalAfter}
				<IconButton onClick={toggleIsPasswordHidden}>
					{isPasswordHidden ? <Icon20View /> : <Icon12EyeSlashOutline width={14} height={14} />}
				</IconButton>
			</div>
		);
	}, [externalAfter, isPasswordHidden, toggleIsPasswordHidden]);

	return <InputField type={isPasswordHidden ? 'password' : 'text'} {...inputProps} after={after} />;
};
