import { useCallback, useEffect, useMemo, useState } from 'react';

export type UseInfinityScrollProps = {
	marker?: HTMLElement | null;
	scrollContainer?: Element | Document | null;
	onMarkerIntersect: () => void;
	threshold?: number | number[];
	rootMargin?: string;
};

export const useInfinityScroll = (props: UseInfinityScrollProps) => {
	const {
		scrollContainer: _scrollContainer = null,
		marker: _marker = null,
		onMarkerIntersect,
		rootMargin,
		threshold = 1.0,
	} = props;

	const [marker, setMarker] = useState(_marker);
	const [scrollContainer, setScrollContainer] = useState(_scrollContainer);

	const intersectHandler: IntersectionObserverCallback = useCallback(
		(entries) => {
			if (entries[0]?.isIntersecting) {
				onMarkerIntersect();
			}
		},
		[onMarkerIntersect],
	);
	const intersectionObserver = useMemo(
		() =>
			new IntersectionObserver(intersectHandler, {
				root: scrollContainer,
				threshold,
				rootMargin,
			}),
		[intersectHandler, scrollContainer, threshold, rootMargin],
	);

	const markerRef = useMemo(() => {
		const ref = (node: HTMLElement | null) => setMarker(node);
		ref.current = marker;

		return ref;
	}, [marker]);

	const scrollContainerRef = useMemo(() => {
		const ref = (node: Element | Document | null) => setScrollContainer(node);
		ref.current = scrollContainer;

		return ref;
	}, [scrollContainer]);

	useEffect(() => {
		if (marker) {
			intersectionObserver.observe(marker);
		}

		return () => {
			if (marker) {
				intersectionObserver.unobserve(marker);
			}
		};
	}, [intersectionObserver, marker]);

	return {
		intersectionObserver,
		intersectHandler,
		markerRef,
		scrollContainerRef,
	};
};
