import axios, { AxiosRequestConfig } from 'axios';
import * as process from 'process';
import { ServerError } from '../core/domain/ServerError/ServerError';

export const instance = axios.create({
	baseURL: process.env.API_BASE_URL,
});

instance.interceptors.response.use(
	(value) => value,
	(error) => {
		return Promise.reject(new ServerError(error));
	},
);

export const ApiService = {
	getUrl: (url: string, params?: Record<string, any>) => {
		return `${window.location.origin}${instance.getUri({ params, url })}`;
	},
	post: async <Response>(url: string, data?: any, config?: AxiosRequestConfig): Promise<Response> => {
		const response = await instance.post<Response>(url, data, config);

		return response.data;
	},
	get: async <Response>(url: string, config?: AxiosRequestConfig): Promise<Response> => {
		const response = await instance.get<Response>(url, config);

		return response.data;
	},
};
