import { IAppError } from './AppError.interface';
import { AppError } from './AppError';

export const AppErrorUtil = {
	is: (data: unknown): data is IAppError => {
		return (
			data instanceof AppError ||
			(typeof data === 'object' && !!data && 'code' in data && typeof data.code === 'string')
		);
	},
};
