import { Layout } from '../../components/Layout';
import { Button, FormItem, FormLayout, FormStatus, Spacing } from '@vkontakte/vkui';
import { InputField } from '../../components/form/InputField';
import { PasswordField } from '../../components/form/PasswordField';
import css from './RegistrationScreen.css';
import { useRegistrationScreenBase } from './useRegistrationScreenBase';

const OFF = true;

export const RegistrationScreen = () => {
	const { submitHandler, isValid, getChangeHandler, values, errors, requestErrorDescription, isFetching } =
		useRegistrationScreenBase();

	if (OFF) {
		return (
			<Layout className={css.root} title="Регистрация" mode="content" header="Регистрация">
				Регистрация закрыта
			</Layout>
		);
	}

	return (
		<Layout className={css.root} title="Регистрация" mode="content" header="Регистрация">
			<FormLayout onSubmit={submitHandler}>
				{requestErrorDescription && <FormStatus mode="error">{requestErrorDescription}</FormStatus>}
				<InputField
					required
					onChange={getChangeHandler('email')}
					error={errors['email']}
					value={values['email']}
					label="Электронная почта"
					name="email"
				/>
				<InputField
					required
					onChange={getChangeHandler('username')}
					error={errors['username']}
					value={values['username']}
					label="Имя пользователя"
					name="username"
				/>
				<PasswordField
					required
					onChange={getChangeHandler('password')}
					error={errors['password']}
					value={values['password']}
					label="Пароль"
					name="password"
				/>
				<Spacing size={16} />
				<FormItem>
					<Button
						loading={isFetching}
						aria-label="Зарегистрироваться"
						disabled={!isValid}
						size="l"
						type="submit"
						stretched
					>
						Зарегистрироваться
					</Button>
				</FormItem>
			</FormLayout>
		</Layout>
	);
};
