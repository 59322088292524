import { RouteObject, RoutesProps, useRoutes } from 'react-router';
import React, { createContext, ReactElement, useCallback, useContext, useMemo, useState } from 'react';
import { Route } from 'react-router-dom';

export function createRoutesFromChildren(children: React.ReactNode, parentPath: number[] = []): RouteObject[] {
	const routes: RouteObject[] = [];

	React.Children.forEach(children, (element, index) => {
		if (!React.isValidElement(element)) {
			// Ignore non-elements. This allows people to more easily inline
			// conditionals in their route config.
			return;
		}

		const treePath = [...parentPath, index];

		if (element.type !== Route) {
			// Transparently support React.Fragment and its children.
			routes.push.apply(routes, createRoutesFromChildren(element.props.children, treePath));

			return;
		}

		if (element.props.index && element.props.children) {
			throw new Error('An index route cannot have child routes.');
		}

		const route: RouteObject = {
			id: element.props.id || treePath.join('-'),
			caseSensitive: element.props.caseSensitive,
			element: element.props.element,
			Component: element.props.Component,
			index: element.props.index,
			path: element.props.path,
			loader: element.props.loader,
			action: element.props.action,
			errorElement: element.props.errorElement,
			ErrorBoundary: element.props.ErrorBoundary,
			hasErrorBoundary: element.props.ErrorBoundary != null || element.props.errorElement != null,
			shouldRevalidate: element.props.shouldRevalidate,
			handle: element.props.handle,
			lazy: element.props.lazy,
		};

		if (element.props.children) {
			route.children = createRoutesFromChildren(element.props.children, treePath);
		}

		routes.push(route);
	});

	return routes;
}
type ExtendedRoutesContextValue = {
	push: (id: string, route: ReactElement) => void;
};
const ExtendedRoutesContext = createContext<ExtendedRoutesContextValue>({
	push: () => {},
});

export const useExtendedRoutes = () => useContext(ExtendedRoutesContext);
const ExtendedRoutesProvider = ExtendedRoutesContext.Provider;

export function ExtendedRoutes(props: Omit<RoutesProps, 'children'> & { children: any }): React.ReactElement | null {
	const { children, location } = props;
	const [routes, setRoutes] = useState<Record<string, ReactElement>>({});

	const routesArray = useMemo(() => Object.values(routes), [routes]);
	const elements = useRoutes(createRoutesFromChildren(routesArray), location);

	const push = useCallback((id: string, route: ReactElement) => setRoutes((prev) => ({ ...prev, [id]: route })), []);
	const value: ExtendedRoutesContextValue = useMemo(() => {
		return {
			push,
		};
	}, [push]);

	return (
		<ExtendedRoutesProvider value={value}>
			{children}
			{elements}
		</ExtendedRoutesProvider>
	);
}
