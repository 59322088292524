import { IFormValidator } from '../domain/Form/Form.type';

export class UrlValidator implements IFormValidator<string, any> {
	validate = (params: { value: string | undefined }) => {
		if (!params.value) {
			return;
		}

		let url;

		try {
			url = new URL(params.value);
		} catch {
			return 'Неверный адрес';
		}

		if (url.protocol !== 'http:' && url.protocol !== 'https:') {
			return 'Адрес должен начинаться с http / https';
		}

		return;
	};
}
