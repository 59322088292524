export const ScreenPath = {
	MAIN: '/',
	LOGIN: '/login',
	REGISTER: '/register',
	PODCASTS: '/podcasts',
	get PODCAST_CREATE(): `${typeof ScreenPath.PODCASTS}/create` {
		return `${ScreenPath.PODCASTS}/create`;
	},
	get PODCAST_EDIT(): `${typeof ScreenPath.PODCAST_VIEW}/edit` {
		return `${ScreenPath.PODCAST_VIEW}/edit`;
	},
	get PODCAST_VIEW(): `${typeof ScreenPath.PODCASTS}/:podcastId` {
		return `${ScreenPath.PODCASTS}/:podcastId`;
	},
	get EPISODE_CREATE(): `${typeof ScreenPath.PODCAST_VIEW}/episode/create` {
		return `${ScreenPath.PODCAST_VIEW}/episode/create`;
	},
	get EPISODE_EDIT(): `${typeof ScreenPath.PODCAST_VIEW}/episode/edit/:episodeId` {
		return `${ScreenPath.PODCAST_VIEW}/episode/edit/:episodeId`;
	},
	get EPISODE_VIEW(): `${typeof ScreenPath.PODCAST_VIEW}/episode/:episodeId` {
		return `${ScreenPath.PODCAST_VIEW}/episode/:episodeId`;
	},
	CONFIRM_EMAIL: '/confirm-email',
	CONFIRM: '/confirm',
} as const;
