import { RegistrationRequestModel, RegistrationResponseModel } from '../core/domain/Auth/Registration.type';
import { ApiService } from './api.service';
import { LoginRequestModel } from '../core/domain/Auth/Login.type';

export const AuthApi = {
	register: (data: RegistrationRequestModel) => {
		return ApiService.post<RegistrationResponseModel>('/auth/register', data);
	},
	login: (data: LoginRequestModel) => {
		return ApiService.post<LoginRequestModel>('/auth/login', data);
	},
	logout: () => {
		return ApiService.post('/auth/logout');
	},
};
