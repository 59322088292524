import { IFormValidator } from '../domain/Form/Form.type';
import { ReactNode } from 'react';

export class EmailValidator implements IFormValidator<string, any> {
	validate = (params: { value: string | undefined }): ReactNode => {
		if (!params.value) {
			return undefined;
		}

		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		return re.test(params.value.toLowerCase()) ? undefined : 'Неправильный адрес электронной почты';
	};
}
