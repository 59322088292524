import { Navigate, Route } from 'react-router-dom';
import { UserStore } from '../../stores/user';
import { ScreenPath } from '../../constant/screen';
import { UserStatus } from '../../core/domain/User/User.constant';
import { LoadingScreen } from '../../screens/LoadingScreen';
import { useId, useLayoutEffect } from 'react';
import { useExtendedRoutes } from './ExtendedRoutes';
import { generateUrl } from '../../util/generateUrl';
import { RouteLoaderProvider } from './RouteLoader';
import { PublicRouteProps } from './PublicRoute';

const REDIRECT_BY_STATUS = {
	[UserStatus.UNVERIFIED]: () => (
		<Navigate
			to={generateUrl(
				ScreenPath.CONFIRM_EMAIL,
				{},
				{
					email: UserStore.store.get().current?.email,
					userId: UserStore.store.get().current?.id,
				},
			)}
			replace
		/>
	),
	[UserStatus.UNAUTHORIZED]: () => <Navigate to={ScreenPath.LOGIN} replace />,
	[UserStatus.VERIFIED]: () => <Navigate to={ScreenPath.MAIN} replace />,
	[UserStatus.UNKNOWN]: () => <LoadingScreen />,
};

export type ProtectedRouteProps = PublicRouteProps & {
	statuses?: UserStatus[];
};
export const ProtectedRoute = (props: ProtectedRouteProps) => {
	const { statuses = [UserStatus.VERIFIED], element, loader, ...routeProps } = props;
	const status = UserStore.useSelector('status');
	const id = useId();
	const { push } = useExtendedRoutes();

	useLayoutEffect(() => {
		push(
			id,
			<Route
				{...routeProps}
				element={
					<RouteLoaderProvider loader={loader}>
						{statuses.includes(status) ? element : REDIRECT_BY_STATUS[status]()}
					</RouteLoaderProvider>
				}
			/>,
		);
	}, [element, id, loader, push, routeProps, status, statuses]);

	return null;
};
