import { Layout } from '../../components/Layout';
import { EpisodeCell } from '../../components/EpisodeCell';
import { usePodcastViewScreenBase } from './usePodcastViewScreenBase';
import { PodcastInfo } from '../../components/PodcastInfo';
import {
	Button,
	ButtonGroup,
	FormStatus,
	Header,
	Placeholder,
	Separator,
	Spacing,
	Title,
	unstable_TextTooltip as TextTooltip,
} from '@vkontakte/vkui';
import {
	Icon20DeleteOutline,
	Icon20RssCircleFillYellow,
	Icon20Sync,
	Icon20WriteOutline,
	Icon24AddSquareOutline,
} from '@vkontakte/icons';
import { LinkButton } from '../../components/LinkButton';
import { generatePath } from 'react-router';
import { ScreenPath } from '../../constant/screen';
import { InfinityScrollContainer } from '../../components/InfinityScrollContainer';

export const PodcastViewScreen = () => {
	const {
		podcastData,
		copyRSSClickHandler,
		isPodcastRestoring,
		isPodcastDeleting,
		remove,
		restore,
		isAllEpisodesFetched,
		episodes,
		episodeErrorCode,
		isEpisodesFetching,
		fetchNextEpisodes,
		isOwner,
		getEpisodeClickHandler,
		totalEpisodeCount,
	} = usePodcastViewScreenBase();

	return (
		<Layout
			title={podcastData ? podcastData.title : 'Подкаст'}
			header="Подкаст"
			action={
				isOwner &&
				podcastData && (
					<ButtonGroup>
						<TextTooltip text="Скопировать ссылку на RSS">
							<Button mode="link" onClick={copyRSSClickHandler}>
								<Icon20RssCircleFillYellow />
							</Button>
						</TextTooltip>
						<TextTooltip text="Редактировать">
							<LinkButton to={generatePath(ScreenPath.PODCAST_EDIT, { podcastId: podcastData.id })} mode="link">
								<Icon20WriteOutline />
							</LinkButton>
						</TextTooltip>
						{podcastData.removed ? (
							<TextTooltip text="Восстановить">
								<Button loading={isPodcastRestoring} mode="link" appearance="positive" onClick={restore}>
									<Icon20Sync />
								</Button>
							</TextTooltip>
						) : (
							<TextTooltip text="Удалить">
								<Button loading={isPodcastDeleting} mode="link" appearance="negative" onClick={remove}>
									<Icon20DeleteOutline />
								</Button>
							</TextTooltip>
						)}
					</ButtonGroup>
				)
			}
		>
			<InfinityScrollContainer hideMarker={isAllEpisodesFetched} onMarkerIntersect={fetchNextEpisodes}>
				{podcastData && <PodcastInfo data={podcastData} />}
				<Spacing size={30} />
				<Header
					aside={
						isOwner &&
						podcastData?.id && (
							<LinkButton mode="link" to={generatePath(ScreenPath.EPISODE_CREATE, { podcastId: podcastData.id })}>
								<Icon24AddSquareOutline />
							</LinkButton>
						)
					}
				>
					<Title level="2">Список эпизодов</Title>
				</Header>
				<Spacing>
					<Separator />
				</Spacing>
				{episodes.map((item, index) => (
					<EpisodeCell
						key={item.id}
						imageSrc={podcastData?.imageUrl ?? ''}
						num={totalEpisodeCount - index}
						explicit={item.explicit}
						description={item.description}
						publishedAt={item.publishedAt}
						audioSrc={item.audioUrl}
						onClick={getEpisodeClickHandler(item.id)}
						isRemoved={item.removed}
					>
						{item.title}
					</EpisodeCell>
				))}
				{!episodes.length && !episodeErrorCode && !isEpisodesFetching && (
					<Placeholder header="Пусто">Вы еще не создали ни одного подкаста :(</Placeholder>
				)}
				{!!episodeErrorCode && (
					<FormStatus mode="error" header="Произошла ошибка">
						Попробуйте позже
					</FormStatus>
				)}
			</InfinityScrollContainer>
		</Layout>
	);
};
