import { generatePath } from 'react-router';

type PathParam<Path extends string> =
	// check if path is just a wildcard
	Path extends '*' | '/*'
		? '*'
		: // look for wildcard at the end of the path
		Path extends `${infer Rest}/*`
		? '*' | _PathParam<Rest>
		: // look for params in the absence of wildcards
		  _PathParam<Path>;

// eslint-disable-next-line @typescript-eslint/naming-convention
type _PathParam<Path extends string> =
	// split path into individual path segments
	Path extends `${infer L}/${infer R}`
		? _PathParam<L> | _PathParam<R>
		: // find params after `:`
		Path extends `:${infer Param}`
		? Param extends `${infer Optional}?`
			? Optional
			: Param
		: // otherwise, there aren't any params present
		  never;

function omitUndefined(obj: Record<string, any>) {
	return Object.keys(obj).reduce((result, key) => {
		if (obj[key] !== undefined && obj[key] !== null) {
			return {
				...result,
				[key]: obj[key],
			};
		}

		return result;
	}, {});
}

export function generateUrl<Path extends string>(
	originalPath: Path,
	params: {
		[key in PathParam<Path>]: string | null;
	} = {} as any,
	queryParams?: Record<string, string | undefined>,
) {
	const query = queryParams ? new URLSearchParams(omitUndefined(queryParams)).toString() : '';

	return generatePath(originalPath, params as any) + (query ? `?${query}` : '');
}
